import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import Sandeep from "../assets/Sadeep.png";
import Sonika from "../assets/Sonika.png";
function Office() {
  return (
    <Stack
      sx={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        height: "auto",
        backgroundColor: "#EFF5FE",
        padding: "30px 0",
      }}
    >
      <Stack
        direction="row"
        sx={{
          width: "88%",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" }, // Stacks vertically on small screens
          textAlign: { xs: "center", sm: "left" },
          marginBottom: 4,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
          Our Directors
        </Typography>
      </Stack>
      <Stack sx={{ width: "100%" }} spacing={{ xs: 5, md: 0 }}>
        <Stack
          direction={{ md: "row" }}
          sx={{
            width: "100%",
            justifyContent: "space-evenly",
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          <Stack
            sx={{
              width: { xs: "100%", md: "40%" },
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              //   backgroundColor: "violet",
              padding: 2,
              gap: 2,
            }}
          >
            <Stack sx={{ width: "55%", height: "20rem", position: "relative" }}>
              <Stack
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#81C2FE",
                  position: "absolute",
                  top: -10,
                  zIndex: 1,
                  left: -10,
                }}
              ></Stack>
              <Stack
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "green",
                  zIndex: 2,
                }}
              >
                <img
                  src={Sandeep}
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              </Stack>
            </Stack>
            <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
              <Typography
                sx={{ color: "#0D65B7", fontSize: "20px", fontWeight: "600" }}
              >
                Sandeep Yadav
              </Typography>
              <Typography sx={{ fontWeight: "600" }}>CEO</Typography>
              <Typography sx={{ fontSize: "13px" }}>
                Sunrise Diagnostic Services
              </Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              width: { xs: "80%", md: "45%" },
              backgroundColor: "white",
              borderRadius: "1.5rem",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              marginTop: "3rem",
            }}
            spacing={2}
          >
            <Typography sx={{ width: "100%", fontSize: "14.5px" }}>
              Sandeep Yadav, the CEO of a leading Sunrise Diagnostic services
              company, is a visionary leader with a strong commitment to
              revolutionizing the healthcare and diagnostics sector. With a deep
              understanding of the industry, Sandeep has focused on enhancing
              the accessibility and accuracy of diagnostic services, integrating
              cutting-edge technology and innovation into the company’s
              operations.{" "}
            </Typography>
            <Typography sx={{ width: "100%", fontSize: "14.5px" }}>
              Under his leadership, the company has expanded its offerings,
              ensuring high-quality, reliable services that cater to healthcare
              professionals. His passion for improving healthcare outcomes
              continues to drive the organization's growth and success.
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction={{ xs: "column", md: "row-reverse" }}
          sx={{
            width: "100%",
            justifyContent: "space-evenly",
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          <Stack
            sx={{
              width: { xs: "100%", md: "40%" },
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              //   backgroundColor: "violet",
              padding: 2,
              gap: 2,
            }}
          >
            <Stack sx={{ width: "55%", height: "20rem", position: "relative" }}>
              <Stack
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#81C2FE",
                  position: "absolute",
                  top: -10,
                  zIndex: 1,
                  left: -10,
                }}
              ></Stack>
              <Stack
                sx={{
                  width: "100%",
                  height: "100%",
                  zIndex: 2,
                }}
              >
                <img
                  src={Sonika}
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              </Stack>
            </Stack>
            <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
              <Typography
                sx={{ color: "#0D65B7", fontSize: "20px", fontWeight: "600" }}
              >
                Sonika Sandeep Yadav
              </Typography>
              <Typography sx={{ fontWeight: "600" }}>Co-Founder</Typography>
              <Typography sx={{ fontSize: "13px" }}>
                Sunrise Diagnostic Services
              </Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              width: { xs: "80%", md: "45%" },
              backgroundColor: "white",
              borderRadius: "1.5rem",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              marginTop: "3rem",
            }}
            spacing={2}
          >
            <Typography sx={{ width: "100%", fontSize: "14.5px" }}>
              Sonika Sandeep Yadav, the Co-founder of a diagnostic services
              company, plays a pivotal role in shaping the vision and strategy
              of the organization. With her innovative mindset and leadership
              skills, Sonika has been instrumental in driving the company's
              mission to deliver accessible, high-quality diagnostic services.
            </Typography>
            <Typography sx={{ width: "100%", fontSize: "14.5px" }}>
              Her expertise and collaborative approach have helped in creating a
              strong foundation for the company’s growth, ensuring that the
              business remains customer-focused while embracing new technologies
              and industry trends. Together with Sandeep Yadav, she continues to
              lead the company toward excellence in the healthcare sector.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Office;
