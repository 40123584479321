import React from "react";
import Slider from "react-slick";
import { Typography, Stack } from "@mui/material";
import test1 from "../assets/test1.png";
import test2 from "../assets/test2.png";
import test3 from "../assets/test3.png";
import test4 from "../assets/test4.png";
import test5 from "../assets/test5.png";

const cards = [
  {
    id: 1,
    content:
      "Purchased diagnostic instruments from Sunshine Diagnostic Services and am extremely satisfied with the experience. The quality of the instruments exceeded my expectations, reflecting the high standards promised by the company.",
    name: "Dr. Mitchell",
    title: "Chief Medical Officer,",
    image: test1,
  },
  {
    id: 2,
    content:
      "From order placement to delivery, Sunshine Diagnostic Services provided an impeccable experience. The diagnostic tools I received are of professional-grade quality, offering the reliability and accuracy that are crucial in a medical setting. I highly recommend them to anyone looking for premium diagnostic instruments.",
    name: "MA. Khan",
    title: "Sneh blood bank kanpur",
    image: test2,
  },
  {
    id: 3,
    content:
      "The quality of the diagnostic instruments from Sunshine Diagnostic Services is second to none. The instruments not only perform exceptionally well but are also ergonomically designed for comfort and ease of use. I’m a loyal customer now!",
    name: " Dr. Anurag Sachan",
    title: "Digestive Care lab Kanpur",
    image: test3,
  },
  {
    id: 4,
    content:
      "Sunshine Diagnostic Services offers the best diagnostic tools I’ve used in my career. The otoscopes and ophthalmoscopes I bought are incredibly precise and easy to handle. The quality of their products is a testament to their commitment to providing the best to healthcare professionals.",
    name: "Dr. Shiddhart Singh",
    title: "Chardrabhan Singh Memorial Hospital",
    image: test4,
  },
  {
    id: 5,
    content:
      "Sunshine Diagnostic Services truly lives up to its reputation. The spirometer and diagnostic ultrasound system I purchased are built to last and deliver precise readings every time. Their attention to detail and quality assurance is evident in every piece of equipment. Worth every penny!",
    name: "Mr. Rahul",
    title: "Military Cantt Hospital Janshi",
    image: test5,
  },
  {
    id: 6,
    content:
      "I’ve been using the diagnostic kits from Sunshine Diagnostic Services for over six months now, and I’m impressed with their durability and accuracy. The instruments are easy to calibrate and maintain, making them a perfect addition to our practice. Couldn’t be happier with my purchase!",
    name: "Mr Mehboob",
    title: "HAL Lucknow",
    image: test4,
  },
  {
    id: 7,
    content:
     "Outstanding service and exceptional quality! The thermometers and pulse oximeters I ordered were better than expected. Sunshine Diagnostic Services has set a new benchmark for reliability and performance in diagnostic instruments. Highly recommend for anyone in the medical field.",
    name: "Richard Rainsford",
    title: "CTO & Co-founder",
    image: test5,
  },
];

// const NextArrow = ({ onClick }) => (
//   <ArrowForwardIos
//     onClick={onClick}
//     style={{ display: "block", color: "blue", fontSize: "30px" }}
//   />
// );

// const PrevArrow = ({ onClick }) => (
//   <ArrowBackIos
//     onClick={onClick}
//     style={{ display: "block", color: "blue", fontSize: "30px" }}
//   />
// );

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Default number of cards to show
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1150, // Adjust to 3 cards on medium screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 700, // Adjust to 1 card on small screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="carousel">
      {cards.map((card) => (
        <Stack
          key={card.id}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
          className="card-container"
        >
          <Stack className="card" spacing={1}>
            <Stack
              sx={{
                width: "5rem",
                height: { xs: "", md: "5rem" },
                borderRadius: "5rem",
                // backgroundColor: "white",
              }}
            >
              <img src={card.image} alt="" />
            </Stack>
            <Typography align="center">
              {card.name}
              <Typography align="center" sx={{ marginTop: -0.5 }}>
                {card.title}
              </Typography>
            </Typography>

            <Typography
              align="center"
              sx={{ fontSize: "13px", width: "16rem" }}
            >
              {card.content}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Slider>
  );
};

export default Carousel;
