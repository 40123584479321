import React, { useState, useEffect } from "react";
import ProductBox from "./ProductBox";
import { Stack, Typography, Grid } from "@mui/material";
import axios from "axios";

function NewAddOns() {
  const [products, setProducts] = useState([]);

  // Fetch the products from the backend
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // Replace this URL with your actual backend endpoint
        const response = await axios.get(`https://api.sunrisediagnosticservice.com/api/products/all`);
        const allProducts = response.data;

        // Get the latest 4 products (assuming the data is already sorted by date)
        const latestProducts = allProducts.slice(0, 4);
        setProducts(latestProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <Stack
      spacing={2}
      sx={{
        width: "100%",
        flexDirection: "column",
        padding: "4rem 0",
        backgroundColor: "#EFF5FE",
        alignItems: "center",
      }}
    >
      <Stack sx={{ width: "88%" }}>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "500",
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          New Add Ons
        </Typography>
      </Stack>
      <Grid
        container
        spacing={2}
        sx={{
          width: "95%",
          justifyContent: "center",
          padding: "0 2rem",
        }}
      >
        {products.map((product) => (
          <Grid
            item
            key={product._id} // Use _id from the fetched data
            xs={12}
            sm={6} // 1 item per row on small screens
            md={3} // 3 items per row on medium screens
            lg={3} // 4 items per row on large screens
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <ProductBox
              // If product.images is empty, show a placeholder image
              id={product._id}

              img={product.img} // Provide a default image if not available
                ProNum={product.model}
                ProTitle={product.productName}
                ProSubTitle={product.ProSubTitle}
                brandName={product.brand}
                price={product.price.value}
                images={product.images}
                // discount={product.discount}// Format the price properly
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}

export default NewAddOns;
