import { Stack, Typography } from '@mui/material'
import React from 'react'
import Carousel from './Carousel'

function Testimonial() {
    return (
        <Stack sx={{
            display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#EFF5FE"
        }}>
            <Typography sx={{ fontSize: "36px", fontWeight: "700" }}>Customer Testimonials</Typography>
            <Typography sx={{
                fontSize: "22px", fontWeight: "400", textAlign: { xs: "center", sm: "left" },
            }}>Discover the high satisfaction shared by
                our clients through their glowing testimonials</Typography>

            <Stack sx={{ width: "100%", height: "28rem", position: "relative", display: "flex", justifyContent: "center", alignItems: "center", marginTop:{xs:0,md:10} }}>
                <Stack sx={{ width: { sm: "100%", md: "88%" }, height: "100%", position: "absolute", zIndex: 0, backgroundColor: "#0D65B7", top: -65 }}>
                </Stack>
                <Stack sx={{ width: "100%", height: "100%", position: "relative", overflow: "hidden", }}>
                    <Carousel />
                </Stack>
            </Stack>

        </Stack>
    )
}

export default Testimonial