import { Stack } from '@mui/material'
import React from 'react'
import DetailsDisplay from '../components/DetailsDisplay'
import Reviews from '../components/Reviews'
import { useParams } from 'react-router-dom';

function ProductDetails() {
  const { productId } = useParams();

  return (
   <Stack>
       <DetailsDisplay productId={productId}/>
       <Reviews product={productId}/>
   </Stack>
  )
}

export default ProductDetails