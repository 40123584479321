// /frontend/Reviews.js

import { Stack, Typography, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);
};
function Reviews({ product }) {
  const starsArr = [1, 2, 3, 4, 5];
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [reviews, setReviews] = useState([]); // For storing fetched reviews
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const productId = product;
  const userId = localStorage.getItem("userId");

  // Redirect to login if no userId
  useEffect(() => {
    if (!userId) {
      window.location.href = "/login";
    }
  }, [userId]);

  // Fetch existing reviews when the component mounts
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(
          `https://api.sunrisediagnosticservice.com/api/review/${productId}`
        );
        setReviews(response.data.reviews);
      } catch (error) {
        setError("Error fetching reviews.");
      }
    };

    fetchReviews();
  }, [productId]);

  const showHoverEffect = (e) => {
    const id = e.target.id;
    if (id <= rating) return;
    for (let i = rating + 1; i <= id; i++) {
      const ele = document.getElementById(i);
      ele.style.fill = "gold";
    }
  };

  const removeHoverEffect = (e) => {
    const id = parseInt(e.target.id);
    if (id <= rating) return;
    for (let i = rating + 1; i <= id; i++) {
      const ele = document.getElementById(i);
      ele.style.fill = "none";
    }
  };

  const giveRating = (e) => {
    const id = parseInt(e.target.id);
    if (id > rating) {
      for (let i = rating + 1; i <= id; i++) {
        const ele = document.getElementById(i);
        ele.style.fill = "gold";
      }
    } else {
      for (let i = id + 1; i <= rating; i++) {
        const ele = document.getElementById(i);
        ele.style.fill = "none";
      }
    }
    setRating(id);
  };

  const handleReviewSubmit = async () => {
    if (rating === 0 || comment === "") {
      setError("Please provide both rating and comment.");
      return;
    }

    try {
      const payload = {
        productId,
        userId,
        rating,
        comment,
      };

      const response = await axios.post(
        `https://api.sunrisediagnosticservice.com/api/review/add`,
        payload
      );

      if (response.status === 200) {
        setSuccess("Review submitted successfully!");
        setError("");
        setRating(0);
        setComment("");

        // Re-fetch reviews to update the list
        const newReviews = await axios.get(
          `https://api.sunrisediagnosticservice.com/api/review/${productId}`
        );
        setReviews(newReviews.data.reviews);
      }
    } catch (error) {
      setError("Error submitting the review. Please try again.");
      setSuccess("");
    }
  };

  // Function to display stars based on rating
  const renderStars = (rating) => {
    return starsArr.map((star, index) => (
      <svg
        key={index}
        width="1cm"
        height="1m"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="200 0 500 500"
      >
        <polygon
          fill={star <= rating ? "gold" : "none"} // Fill gold for stars less than or equal to rating
          stroke="gray"
          strokeWidth="10"
          points="350,75  379,161 469,161 397,215 423,301 350,250 277,301 303,215 231,161 321,161"
        />
      </svg>
    ));
  };

   // Function to calculate the average rating
   const getAverageRating = () => {
    if (reviews.length === 0) return 0;
    const totalRating = reviews.reduce((acc, review) => acc + review.rating, 0);
    return (totalRating / reviews.length).toFixed(1);
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: "auto",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#EFF5FE",
      }}
    >
      <Stack
        direction={{ xs: "column", md: "row" }}
        sx={{
          width: "75%",
          height: "100%",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
        spacing={2}
      >
        <Stack sx={{ width: { xs: "100%", md: "55%" }, height: "100%" }}>
          <Typography sx={{ fontSize: "22px", fontWeight: "600" ,marginBottom:5}}>Rating</Typography>

          <Stack direction="row" sx={{ width: "100%", height: "30%" }}>
            <Stack
              sx={{
                width: "30%",
                height: "100%",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
              spacing={3}
            >
              <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              {getAverageRating()}/5 {/* Display the average rating */}
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                Reviewed by {reviews.length} Users
              </Typography>
            </Stack>

            <Stack
              direction="row"
              sx={{
                width: "75%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
              spacing={1}
            >
              {starsArr.map((id) => (
                <div key={id}>
                  <svg
                    width="1.5cm"
                    height="1.5cm"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="200 0 500 500"
                  >
                    <polygon
                      fill="none"
                      id={id}
                      stroke="black"
                      onClick={giveRating}
                      onMouseOver={showHoverEffect}
                      onMouseLeave={removeHoverEffect}
                      strokeWidth="10"
                      points="350,75  379,161 469,161 397,215 423,301 350,250 277,301 303,215 231,161 321,161"
                    />
                  </svg>
                </div>
              ))}
            </Stack>
          </Stack>

          <Typography>Write your review</Typography>
          <textarea
            rows={8}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            style={{
              width: "100%",
              height: "6rem",
              border: "0.5px solid black",
              margin: "8px 0 25px",
            }}
          />

          {error && <Typography sx={{ color: "red" }}>{error}</Typography>}
          {success && (
            <Typography sx={{ color: "green" }}>{success}</Typography>
          )}

          <Stack
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              onClick={handleReviewSubmit}
              sx={{
                width: "45%",
                height: "100%",
                borderRadius: "0.3rem",
                backgroundColor: "#EEA92B",
                color: "white",
                fontWeight: "400",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textDecoration: "none",
              }}
            >
              Submit Review
            </Button>
          </Stack>

          <Stack sx={{ width: "100%", marginTop: "30px" }}>
            <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
              Reviews
            </Typography>
            {reviews.length === 0 ? (
              <Typography>No reviews yet.</Typography>
            ) : (
              reviews.map((review) => (
                <Stack
                  key={review._id}
                  sx={{
                    margin: "10px 0",
                    borderBottom: "0.1px solid gray",
                    padding: 1,
                  }}
                >
                  <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                    {review.userName}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", fontWeight: "300" }}>
                    {formatDate(review.createdAt)}
                  </Typography>
                  <Stack direction="row" spacing={0} sx={{ marginTop: 1 }}>
                    {renderStars(review.rating)}{" "}
                    {/* Display stars for rating */}
                  </Stack>
                  <Typography sx={{ fontSize: "15px", color: "#555" }}>
                    {review.comment}
                  </Typography>
                </Stack>
              ))
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Reviews;
