import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Input, Stack, Switch } from '@mui/material';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  bgcolor: '#EFF5FE',
  boxShadow: 24,
  borderRadius: 2,
  height: "auto"
};

function ProductAddForm() {
  const [open, setOpen] = useState(false);
  const [productName, setProductName] = useState('');
  const [model, setModel] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState({  value: '' });
  const [availability, setAvailability] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [brand, setBrand] = useState('');
  const [throughput, setThroughput] = useState('');
  const [display, setDisplay] = useState('');
  const [usage, setUsage] = useState('');
  const [dataStorage, setDataStorage] = useState('');
  const [images, setImages] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFileChange = (e) => {
    setImages(e.target.files);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('productName', productName);
    formData.append('model', model);
    formData.append('weight', weight);
    formData.append('height', height);
    formData.append('description', description);
    formData.append('price[value]', price.value); // Correct way to append
    formData.append('availability', availability);
    formData.append('discount', discount);
    formData.append('brand', brand);
    formData.append('throughput', throughput);
    formData.append('display', display);
    formData.append('usageApplication', usage);
    formData.append('dataStorage', dataStorage);

    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i]);
    }

    try {
      await axios.post(`https://api.sunrisediagnosticservice.com/api/products/add`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      handleClose(); // Close the modal after successful submission
      window.location.reload(); // This will reload the entire page

    } catch (error) {
      console.error('There was an error submitting the form!', error);
    }
  };

  return (
    <div>
      <Button onClick={handleOpen} sx={{ backgroundColor: "#0D65B7", padding: 1.3, borderRadius: "0.7rem", color: "white", fontWeight: "600", fontSize: "13px" }}>Add Product</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack>
            <Stack sx={{ width: "100%", borderBottom: "0.5px solid black" }}>
              <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center", padding: 2 }}>
                <Typography>Products</Typography>
                <Stack
                  sx={{
                    padding: "3.5px 8px",
                    border: "1px solid black", borderRadius: 5, color: "black",
                    justifyContent: "center", alignItems: "center",
                    cursor: 'pointer'
                  }}
                  onClick={handleClose}
                >
                  x
                </Stack>
              </Stack>
            </Stack>

            <Stack direction="row" sx={{ padding: 2, alignItems: "flex-start", justifyContent: "space-between" }}>
              <Stack sx={{ width: "65%" }}>
                <Stack direction="row" sx={{ width: "100%", justifyContent: "space-between" }}>
                  <Stack>
                    <Typography sx={{ padding: "0px 0 10px" }}>Product Information</Typography>
                    <Typography>Name</Typography>
                    <Input
                      sx={{ width: "18rem", height: "2.2rem", border: "0.5px solid black", margin: "0px 0 20px" }}
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                    />
                  </Stack>
                </Stack>

                {/* Model, Weight, Height Inputs */}
                <Stack direction="row" sx={{ width: "100%", justifyContent: "space-between" }}>
                  <Stack sx={{ width: "30%" }}>
                    <Typography>Model</Typography>
                    <Input
                      sx={{ width: "100%", height: "2.2rem", border: "0.5px solid black", margin: "0px 0 20px" }}
                      value={model}
                      onChange={(e) => setModel(e.target.value)}
                    />
                  </Stack>
                  <Stack sx={{ width: "30%" }}>
                    <Typography>Weight</Typography>
                    <Input
                      sx={{ width: "100%", height: "2.2rem", border: "0.5px solid black", margin: "0px 0 20px" }}
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                    />
                  </Stack>
                  <Stack sx={{ width: "30%" }}>
                    <Typography>Height</Typography>
                    <Input
                      sx={{ width: "100%", height: "2.2rem", border: "0.5px solid black", margin: "0px 0 20px" }}
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                    />
                  </Stack>
                </Stack>

                {/* Description and Price Inputs */}
                <Stack sx={{ width: "100%" }}>
                  <Typography>Description</Typography>
                  <Input
                    sx={{ width: "100%", height: "3rem", border: "0.5px solid black", margin: "0px 0 20px" }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Stack>
                <Stack direction="row" sx={{ width: "100%", justifyContent: "space-between" }}>
                  <Stack sx={{ width: "30%" }}>
                    <Typography>Price</Typography>
                    <Input
                      sx={{ width: "100%", height: "2.2rem", border: "0.5px solid black", margin: "0px 0 20px" }}
                      value={price.value}
                      onChange={(e) => setPrice({ ...price, value: e.target.value })}
                    />
                  </Stack>
                  {/* <Stack sx={{ width: "30%" }}>
                    <Typography>Currency</Typography>
                    <Select
                      sx={{ width: "100%", height: "2.2rem", border: "0.5px solid black" }}
                      value={price.currency}
                      onChange={(e) => setPrice({ ...price, currency: e.target.value })}
                    >
                      <MenuItem value="USD">USD</MenuItem>
                      <MenuItem value="EUR">EUR</MenuItem>
                    </Select>
                  </Stack> */}
                </Stack>

                {/* Brand, Throughput, Display, Usage Inputs */}
                <Stack direction="row" sx={{ width: "100%", justifyContent: "space-between" }}>
                  <Stack sx={{ width: "30%" }}>
                    <Typography>Brand</Typography>
                    <Input
                      sx={{ width: "100%", height: "2.2rem", border: "0.5px solid black", margin: "0px 0 20px" }}
                      value={brand}
                      onChange={(e) => setBrand(e.target.value)}
                    />
                  </Stack>
                  <Stack sx={{ width: "30%" }}>
                    <Typography>Throughput</Typography>
                    <Input
                      sx={{ width: "100%", height: "2.2rem", border: "0.5px solid black", margin: "0px 0 20px" }}
                      value={throughput}
                      onChange={(e) => setThroughput(e.target.value)}
                    />
                  </Stack>
                  <Stack sx={{ width: "30%" }}>
                    <Typography>Display</Typography>
                    <Input
                      sx={{ width: "100%", height: "2.2rem", border: "0.5px solid black", margin: "0px 0 20px" }}
                      value={display}
                      onChange={(e) => setDisplay(e.target.value)}
                    />
                  </Stack>
                </Stack>
                <Stack sx={{ width: "100%" }}>
                  <Typography>Usage</Typography>
                  <Input
                    sx={{ width: "100%", height: "2.2rem", border: "0.5px solid black", margin: "0px 0 20px" }}
                    value={usage}
                    onChange={(e) => setUsage(e.target.value)}
                  />
                </Stack>

                {/* Data Storage and Availability Inputs */}
                <Stack direction="row" sx={{ width: "100%", justifyContent: "space-between" }}>
                  <Stack sx={{ width: "30%" }}>
                    <Typography>Data Storage</Typography>
                    <Input
                      sx={{ width: "100%", height: "2.2rem", border: "0.5px solid black", margin: "0px 0 20px" }}
                      value={dataStorage}
                      onChange={(e) => setDataStorage(e.target.value)}
                    />
                  </Stack>
                  <Stack sx={{ width: "30%" }}>
                    <Typography>Availability</Typography>
                    <Switch
                      checked={availability}
                      onChange={(e) => setAvailability(e.target.checked)}
                    />
                  </Stack>
                </Stack>

                <Stack sx={{ width: "100%" }}>
                  <Typography>Discount</Typography>
                  <Input
                    sx={{ width: "100%", height: "2.2rem", border: "0.5px solid black", margin: "0px 0 20px" }}
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </Stack>
              </Stack>

              <Stack sx={{ width: "30%", padding: 2 }}>
                <Typography>Upload Images (PNG, JPEG, JPG)</Typography>
                <Input
                  type="file"
                  inputProps={{ multiple: true }}
                  onChange={handleFileChange}
                  sx={{ border: "0.5px solid black", padding: "5px" }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ padding: 2, textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#0D65B7", color: "white" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

export default ProductAddForm;
