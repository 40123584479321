import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import Office1 from "../assets/Office1.png"
import Office2 from "../assets/Office2.png"

function Team() {
  return (
    <Stack
      sx={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        height: "auto",
        backgroundColor: "#EFF5FE",
        padding: "30px 0",
      }}
    >
      <Stack
        direction="row"
        sx={{
          width: "88%",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" }, // Stacks vertically on small screens
          textAlign: { xs: "center", sm: "left" },
          marginBottom: 4,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
          Our Team
        </Typography>
      </Stack>
      <Stack
        sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
        spacing={5}
      >
        <Stack
          direction={{xs:"column", md: "row" }}
          sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
          spacing={3}
        >
          <Stack
            sx={{ width: { xs: '90%', md: '40%' }, height: "22rem", backgroundColor: "green" }}
          >
            <img src={Office1} style={{width:"100%",height:"100%"}}  alt="" />
          </Stack>
          <Stack
            sx={{ width: { xs: '90%', md: '40%' }, height: "22rem", backgroundColor: "green" }}
          >
            <img src={Office2} style={{width:"100%",height:"100%"}}  alt="" />
          </Stack>
        </Stack>
        <Typography sx={{ width: "88%", fontSize: "17px" }}>
          Our team at{" "}
          <span style={{ fontWeight: "600", color: "#a6200c" }}>
            Sunrise Diagnostic Services
          </span>{" "}
          is composed of dedicated professionals committed to delivering
          excellence in diagnostic services. Each member brings unique expertise
          and passion, ensuring that we provide the highest standards of
          accuracy, efficiency, and customer care. Our team members pool their
          expertise to solve problems, innovate, and deliver high-quality,
          accurate diagnostic solutions. This collective effort drives both
          individual and company-wide success. Their collective dedication and
          expertise are the driving force behind our success, making a
          meaningful impact in the healthcare industry every day.
        </Typography>

        <Typography
          sx={{ width: { xs: '90%', md: '50%' }, fontSize: "17px", textAlign: "center" }}
        >
          “we ensure that our team stays aligned with the company’s mission to
          provide accurate, efficient, and accessible diagnostics”.
        </Typography>
      </Stack>
    </Stack>
  );
}

export default Team;
