import React from 'react'
import { Stack, Typography } from '@mui/material';
import estimates from "../assets/estimates.png"
function Estimates() {

    const est = [
        {
            id: 1,
            num: "3000+",
            title: "Trade visitors"
        },
        {
            id: 2,
            num: "20+",
            title: "Best Brands"
        },
        {
            id: 3,
            num: "100+",
            title: "Diagnostic Products"
        },
        {
            id: 4,
            num: "40+",
            title: "Participating Contries"
        }
    ]
    return (
        <Stack sx={{
            width: '100%',
            height: '',
            display: "flex",
            flexDirection: { xs:"col",md:"row"},
            justifyContent: "center",
            backgroundImage: `url(${estimates})`,
            backgroundSize: 'cover',
            gap:{xs:"1rem",md:"10rem"},
            alignItems: "center",
            padding:"1rem 0"
        }}>
            <Typography sx={{
                color: "#FAFDFF",
                fontWeight: "600", fontSize: "24px", width: "18rem",
                textAlign:{xs:"center",md:"left"}
            }}>
                Quality Equipment from TOP-Tier
                Manufactures
            </Typography>
            <Stack sx={{
                flexDirection: "row", height: "100%",gap:2,
                justifyContent: "center",
                            alignItems: "center",
                            flexWrap:"wrap"
            }}>
                {est.map((es) => (
                    <Stack
                        sx={{
                            width: "10rem",
                            height: "8rem",
                            backgroundColor: "rgba(94, 172, 252, 0.72)",
                            borderRadius: "0.5rem",
                            display: "flex",  // Corrected from "flax" to "flex"
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                        key={es.id}
                    >
                        <Typography sx={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "22px"
                        }}>
                            {es.num}
                        </Typography>
                        <Typography sx={{
                            color: "black",
                            fontWeight: "300",
                            fontSize: "16px"
                        }}>
                            {es.title}
                        </Typography>
                    </Stack>

                ))}
            </Stack>
        </Stack>
    )
}

export default Estimates