import { Stack, Typography } from "@mui/material";
import React from "react";
import logo from "../assets/logo.png";
import vision from "../assets/vision.png";
import values from "../assets/values.png";
import mission from "../assets/mission.png";
function Vision() {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "auto",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        backgroundColor: "#EFF5FE",

      }}
    >
      <Stack
        sx={{
          width: {xs:"90%",md:"70%"},
          justifyContent: "center",
          alignItems: "center",
          padding: "30px 0",
        }}
      >
        <Typography sx={{ textAlign: "center" ,fontSize:{xs:"14px",md:"18px"}}}>
          “Welcome to Sunrise Diagnostic Services, was founded and promoted in
          2016, a trusted provider of high-quality diagnostic service equipment
          for healthcare professionals and laboratories. We offer a wide range
          of advanced diagnostic tools and devices designed to deliver accurate
          and reliable results across various medical fields. Whether you're
          outfitting a new lab or upgrading your existing equipment, we provide
          cutting-edge solutions that ensure precision and efficiency in
          diagnostics. With our commitment to innovation, customer support, and
          competitive pricing, Sunrise Diagnostic services is your go-to source
          for all your diagnostic equipment needs”.
        </Typography>
      </Stack>

      <Stack
        direction={{ xs: "column", md: "column" }}
        sx={{
          width: "80%",
          height: "auto",
          backgroundColor: "#053f74",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: 8,
          gap: 6,
          borderRadius: 4,
        }}
      >
        <Stack
          direction={{ md: "row" }}
          sx={{
            width: "100%",
            justifyContent: "space-evenly",
            alignItems: { xs: "center", md: "flex-start" },
            // backgroundColor:"red"
          }}
        >
          <Stack
            sx={{
              width: { xs: "100%", md: "60%" },
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "violet",
              padding: 2,
              gap: 2,
            }}
          >
            <Stack sx={{ width: { xs: "100%", md: "60%" }, height: "20rem", position: "relative" }}>
              <Stack
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#81C2FE",
                  position: "absolute",
                  top: -10,
                  zIndex: 1,
                  left: -10,
                }}
              ></Stack>
              <Stack
                sx={{
                  width: "100%",
                  height: "100%",
                  background: "linear-gradient(180deg, rgba(199,227,253,1) 0%, rgba(26,118,204,1) 89%)",
                  zIndex: 2,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={logo}
                  style={{ width: "18rem", height: "12rem" }}
                  alt=""
                />
                <Typography
                  variant="h3"
                  noWrap
                  component="div"
                  sx={{ display: { xs: "flex", md: "flex" } ,color:"white"}}
                >
                  Sunrise
                </Typography>
                <Typography sx={{ fontSize: "18px", marginTop: -1 ,color:"white"}}>
                  Diagnostic Services
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              width: { xs: "100%", md: "55%" },
              // backgroundColor: "white",
              borderRadius: "1.5rem",
              justifyContent: "center",
              alignItems: "center",
              // padding: "20px",
              marginTop: "3rem",
            }}
            spacing={2}
          >
            <Typography
              sx={{
                width: "90%",
                fontSize: "23px",
                color: "#eea92b",
                fontWeight: "600",
              }}
            >
              Why Choose Sunrise Diagnostic services?{" "}
            </Typography>
            <Typography sx={{ width: "100%", fontSize: "18px",color:"white"}}>
              At our Sunrise Diagnostic services, we offer a curated selection
              of cutting-edge diagnostic products designed for accuracy and
              reliability. All our products are sourced from trusted
              manufacturers and adhere to the highest industry standards,
              ensuring quality and safety. With user-friendly interfaces and
              detailed product descriptions, shopping is made easy and
              informative.
            </Typography>
            <Typography sx={{ width: "100%", fontSize: "18px" ,color:"white"}}>
              We provide fast shipping and secure payment options, so you can
              purchase with confidence. Our dedicated customer support is always
              ready to assist with any inquiries or guidance you need. Choose us
              for reliable, high-quality diagnostic products at your
              convenience.
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction={{xs:"column",md:"row"}}
          sx={{ justifyContent: "center", alignItems: "center", width: "100%" ,gap:5}}
        >
          <Stack
            sx={{
              width: {xs:"100%",md:"33%"},
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            
            }}
          >
            <Stack
              direction="row"
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap:3,
                width: {xs:"100%",md:"80%"},
                mb:2
              }}
            >
              <Stack
                sx={{ width: "6rem", height: "5rem", }}
              >
                <img alt="" src={vision} style={{ width: "100%", height: "100%" }} />
              </Stack>
              <Typography sx={{ fontSize: "24px", fontWeight: "600",color:"#eea92b" }}>
                Our Vision
              </Typography>
            </Stack>
            <Typography sx={{ width: {xs:"100%",md:"80%"},color:"white" }}>
              Providing the highest levels of cost-effective solutions and best
              quality instruments to our customers, through continued research
              and development.
            </Typography>
          </Stack>
          <Stack
            sx={{
              width: {xs:"100%",md:"33%"},
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Stack
              direction="row"
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
                width: {xs:"100%",md:"80%"},
                mb:2
              }}
            >
              <Stack
                sx={{ width: "6rem", height: "5rem" }}
              >
                <img alt="" src={mission} style={{ width: "100%", height: "100%" }} />
              </Stack>
              <Typography sx={{ fontSize: "28px", fontWeight: "600",color:"#eea92b" }}>
                Our Mission
              </Typography>
            </Stack>
            <Typography sx={{ width: {xs:"100%",md:"80%"},color:"white"}}>
            To have the most effective, innovative products and efficiently run sales and Develop new products and operating systems in the industry.
            </Typography>
          </Stack>

          <Stack
            sx={{
              width: {xs:"100%",md:"33%"},
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Stack
              direction="row"
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 0,
                width: {xs:"100%",md:"80%"}
              }}
            >
              <Stack
                sx={{ width: "6rem", height: "6rem", }}
              >
                <img alt="" src={values} style={{ width: "100%", height: "100%" }} />
              </Stack>
              <Typography sx={{ fontSize: "28px", fontWeight: "600",color:"#eea92b" }}>
                Our Values
              </Typography>
            </Stack>
            <Typography sx={{  width: {xs:"100%",md:"80%"},color:"white" }}>
            Ensuring a secure shopping environment and promoting eco-friendly practices. Dedicated engineering & technical support.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Vision;
