import React from 'react'
import Office from '../components/Office'
import Team from '../components/Team'

function About() {
  return (
    <div>
        <Office/>
        <Team/>
    </div>
  )
}

export default About