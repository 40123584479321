import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { Link, useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import logo from "../assets/logo.png";

const topPages = ['Signup'];
const bottomPages = ['Home', 'About', 'Shop', 'Help'];

function Navbar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const navigate = useNavigate(); // For navigation

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

  

    // Logout function
    const handleLogout = () => {
        localStorage.removeItem('jwtToken');  // Remove the token
        localStorage.removeItem('userId');  // Remove admin status if exists
        navigate('/signup');  // Redirect to signup/login page
    };

    return (
        <>
            <AppBar position="static" sx={{ backgroundColor: "#0D65B7" }}>
                <Container maxWidth="l">
                    <Toolbar disableGutters>
                        <Stack sx={{ width: "7rem", height: "auto" }}>
                            <Stack sx={{ width: "4.2rem", height: "2.5rem" }}>
                                <img src={logo} alt="" />
                            </Stack>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ display: { xs: 'flex', md: 'flex' } }}
                            >
                                Sunrise
                            </Typography>
                            <Typography sx={{ fontSize: "12px", marginTop: -1 }}>
                                Diagnostic Services
                            </Typography>
                        </Stack>

                        {/* Mobile Menu */}
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
                            <IconButton
                                size="large"
                                aria-label="menu"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {[...topPages, ...bottomPages].map((page) => (
                                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">
                                          
                                                <Link to={`/${page.toLowerCase()}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                    {page}
                                                </Link>
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                        {/* Desktop View */}
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                            {/* Container for Top and Bottom Rows */}
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                {/* Top Row */}
                                <Box sx={{ display: 'flex' }}>
                                    {topPages.map((page) => (
                                        <Button
                                            key={page}
                                            onClick={handleCloseNavMenu}
                                            sx={{ mt: 1, color: 'white', display: 'block' }}
                                        >
                                            <Link to={`/${page.toLowerCase()}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                {page}
                                            </Link>
                                        </Button>
                                    ))}
                                </Box>
                                {/* Bottom Row with reduced margin */}
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {bottomPages.map((page) => (
                                        <Button
                                            key={page}
                                            onClick={handleCloseNavMenu}
                                            sx={{ mb: 1, color: 'white', display: 'block' }}
                                        >
                                          
                                                <Link to={`/${page.toLowerCase()}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                    {page}
                                                </Link>
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                        </Box>

                        {/* Logout Button */}
                        {localStorage.getItem('userId') && (
                            <Button
                                onClick={handleLogout}
                                sx={{ color: 'black', marginLeft: 2 , backgroundColor:"#fead44" }}
                            >
                                Logout
                            </Button>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
}

export default Navbar;
