import { Stack, Typography, Input, Button } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import logo from "../assets/logo.png";
import dots from "../assets/dots.png";
import { ToastContainer, toast } from "react-toastify"; // Import react-toastify
import "react-toastify/dist/ReactToastify.css";

function Auth() {
  const [isSignIn, setIsSignIn] = useState(true);
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate(); // Initialize useNavigate for navigation

  const handleToggle = () => {
    setIsSignIn(!isSignIn);
    setError(""); // Clear error on toggle
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        email,
        password,
      };

      if (!isSignIn) {
        if (password !== confirmPassword) {
          setError("Passwords do not match");
          toast.error("Passwords do not match"); // Display error toast
          return;
        }
        payload.name = name;
        payload.phone = number;
      }

      const response = isSignIn
        ? await axios.post(`https://api.sunrisediagnosticservice.com/api/auth/login`, payload)
        : await axios.post(`https://api.sunrisediagnosticservice.com/api/auth/register`, payload);

      if (!isSignIn) {
        // Toggle to sign in after successful registration
        setIsSignIn(true);
        setEmail("");
        setNumber("");
        setPassword("");
        setName("");
        setConfirmPassword("");
        setError(""); // Clear any previous errors
        toast.success("Account created successfully!"); // Display success toast for signup
      } else {
        // Save the JWT token to local storage after successful login
        const token = response.data.token; // Assuming the token is in the response data
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("userId", response.data.user._id);
        // Navigate to home page
        navigate("/");
      }
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred");
      toast.error(err.response?.data?.message || "An error occurred"); // Display error toast
    }
  };

  // Navigate to the home page
  const goToHome = () => {
    navigate("/home");
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#DFF0FF",
      }}
    >
      <ToastContainer />
      <Stack
        direction="row"
        sx={{
          width: { sm: "100%", md: "65%" },
          height: { sm: "45rem", md: "43rem" },
          borderRadius: 5,
        }}
      >
        <Stack
          sx={{
            width: "50%",
            height: "100%",
            backgroundColor: "#0D65B7",
            borderRadius: "1rem 0 0 1rem",
          }}
        >
          <Stack
            sx={{
              width: "100%",
              height: "20%",
              justifyContent: "center",
              alignItems: "flex-start",
              padding: "0 3rem",
            }}
          >
            <Stack sx={{ width: "3.5rem", height: "3.5rem" }}>
              <img src={dots} alt="design" />
            </Stack>
          </Stack>
          <Stack
            sx={{
              width: "100%",
              height: "30%",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              top: -50,
            }}
          >
            <Stack
              sx={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack sx={{ width: "8rem", position: "relative", right: 20 }}>
                <img src={logo} alt="logo" />
              </Stack>
            </Stack>
            <Stack
              sx={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "16px", color: "white" }}>
                <Typography sx={{ fontSize: "26px" }}>Sunrise</Typography>
                Diagnostic Services
              </Typography>
            </Stack>
          </Stack>

          <Stack
            sx={{ height: "30%", padding: "0 3rem", justifyContent: "center" }}
          >
            <Typography sx={{ fontSize: "20px", color: "white" }}>
              Welcome to...
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "white" }}>
              "Sunrise Diagnostic Services, where cutting-edge diagnostic
              equipment meets precision and reliability. Explore our advanced
              solutions to empower your healthcare and research with the highest
              standards of accuracy."
            </Typography>
          </Stack>

          <Stack
            sx={{
              width: "100%",
              height: "20%",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Stack sx={{ width: "3.5rem", height: "3.5rem", margin: "0 3rem" }}>
              <img src={dots} alt="design" />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            width: "50%",
            height: "100%",
            backgroundColor: "white",
            borderRadius: "0rem 1rem 1rem 0rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack sx={{ width: "70%" }} spacing={isSignIn ? 2.5 : 0}>
            <Stack>
              <Typography sx={{ fontSize: "26px", fontWeight: "600" }}>
                {isSignIn ? "Sign In" : "Create Account"}
              </Typography>
              <Typography sx={{ fontSize: "13px", color: "#656C71" }}>
                {isSignIn
                  ? "Enter your details to sign in your account"
                  : "Enter your details to create a new account"}
              </Typography>
            </Stack>

            {!isSignIn && (
              <Stack>
                <Typography>Name</Typography>
                <Input
                  sx={{
                    width: "100%",
                    height: "2.4rem",
                    border: "0.3px solid black",
                    margin: "8px 0 25px",
                    backgroundColor: "#F3F5F9",
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Stack>
            )}

            <Stack>
              <Typography>Email Address</Typography>
              <Input
                sx={{
                  width: "100%",
                  height: "2.4rem",
                  border: "0.3px solid black",
                  margin: "8px 0 25px",
                  backgroundColor: "#F3F5F9",
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Stack>

            {!isSignIn && (
              <Stack>
                <Typography>Phone Number</Typography>
                <Input
                  sx={{
                    width: "100%",
                    height: "2.4rem",
                    border: "0.3px solid black",
                    margin: "8px 0 25px",
                    backgroundColor: "#F3F5F9",
                  }}
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </Stack>
            )}

            <Stack>
              <Typography>Password</Typography>
              <Input
                sx={{
                  width: "100%",
                  height: "2.4rem",
                  border: "0.3px solid black",
                  margin: "8px 0 25px",
                  backgroundColor: "#F3F5F9",
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
            </Stack>

            {!isSignIn && (
              <Stack>
                <Typography>Confirm Password</Typography>
                <Input
                  sx={{
                    width: "100%",
                    height: "2.4rem",
                    border: "0.3px solid black",
                    margin: "8px 0 25px",
                    backgroundColor: "#F3F5F9",
                  }}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                />
              </Stack>
            )}

            {error && <Typography sx={{ color: "red" }}>{error}</Typography>}

            <Stack>
              <Button
                sx={{
                  width: "100%",
                  height: "2.4rem",
                  backgroundColor: "#0D65B7",
                  color: "white",
                  ':hover': {
                    backgroundColor: "#0D65B7", // Prevent hover transparency
                    boxShadow: "none", // Remove shadow on hover if needed
                  },
                }}
                onClick={handleSubmit}
              >
                {isSignIn ? "SIGN IN" : "CREATE ACCOUNT"}
              </Button>
            </Stack>

            <Stack sx={{ marginTop: "1rem" }}>
              <Button
                sx={{
                  width: "100%",
                  height: "2.4rem",
                  backgroundColor: "#656C71",
                  color: "white",
                  ':hover': {
                    backgroundColor: "#656C71", // Prevent hover transparency
                    boxShadow: "none", // Remove shadow on hover if needed
                  },
                }}
                onClick={goToHome} // Back to Home button
              >
                Back to Home
              </Button>
            </Stack>

            <Stack
              direction="row"
              sx={{ justifyContent: "center", alignItems: "center" }}
              spacing={1}
            >
              <Stack
                sx={{ width: "25%", height: 0.05, backgroundColor: "black" }}
              ></Stack>
              <Typography>or</Typography>
              <Stack
                sx={{ width: "25%", height: 0.05, backgroundColor: "black" }}
              ></Stack>
            </Stack>

            <Stack
              direction="row"
              sx={{ justifyContent: "center", alignItems: "center" }}
              spacing={1}
            >
              <Typography>
                {isSignIn
                  ? "Don’t have an account?"
                  : "Already have an account?"}
              </Typography>
              <Typography
                sx={{ color: "#2694F7", cursor: "pointer" }}
                onClick={handleToggle}
              >
                {isSignIn ? "Create Account" : "Sign In"}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Auth;
