// File: src/components/RequirementTableAdmin.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
//   Button,
//   TextField,
//   Snackbar,
//   Alert,
} from "@mui/material";

function RequirementTableAdmin() {
  const [requirements, setRequirements] = useState([]);
//   const [editableRequirement, setEditableRequirement] = useState(null);
//   const [editedData, setEditedData] = useState({});
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    fetchRequirements();
  }, []);

  const fetchRequirements = () => {
    axios
      .get(`https://api.sunrisediagnosticservice.com/api/requirement/all`)
      .then((response) => {
        setRequirements(response.data.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the requirements!", error);
      });
  };

//   const handleEdit = (requirement) => {
//     setEditableRequirement(requirement._id);
//     setEditedData({
//       name: requirement.name,
//       phone: requirement.phone,
//       email: requirement.email,
//       subject: requirement.subject,
//       requirement: requirement.requirement,
//     });
//   };

//   const handleSave = (requirement) => {
//     axios
//       .patch(
//         `http://13.201.223.150/api/requirements/update`,
//         { ...editedData, id: requirement._id }
//       )
//       .then((response) => {
//         setRequirements((prevRequirements) =>
//           prevRequirements.map((r) =>
//             r._id === requirement._id ? { ...response.data } : r
//           )
//         );
//         setEditableRequirement(null);
//         setAlertMessage("Requirement updated successfully!");
//         setOpenSnackbar(true);
//       })
//       .catch((error) => {
//         console.error("There was an error updating the requirement!", error);
//         setAlertMessage("Error updating the requirement!");
//         setOpenSnackbar(true);
//       });
//   };

//   const handleDelete = (requirementId) => {
//     axios
//       .delete(`http://13.201.223.150/api/requirements/delete`, {
//         data: { id: requirementId },
//       })
//       .then(() => {
//         setRequirements(requirements.filter((r) => r._id !== requirementId));
//         setAlertMessage("Requirement deleted successfully!");
//         setOpenSnackbar(true);
//       })
//       .catch((error) => {
//         console.error("There was an error deleting the requirement!", error);
//         setAlertMessage("Error deleting the requirement!");
//         setOpenSnackbar(true);
//       });
//   };

//   const handleCancel = () => {
//     setEditableRequirement(null);
//   };

//   const handleSnackbarClose = () => {
//     setOpenSnackbar(false);
//   };

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          backgroundColor: "#EFF5FE",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
        spacing={1}
      >
        <Stack sx={{ width: "95%", height: "88%" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#0D65B7" }}>
                  <TableCell sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}>Name</TableCell>
                  <TableCell sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}>Phone</TableCell>
                  <TableCell sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}>Email</TableCell>
                  <TableCell sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}>Subject</TableCell>
                  <TableCell sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}>Requirement</TableCell>
                  {/* <TableCell sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}>Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {requirements.map((requirement) => (
                  <TableRow key={requirement._id} sx={{ height: "5rem" }}>
                    <TableCell> <Typography>{requirement.name}</Typography></TableCell>
                    <TableCell> <Typography>{requirement.phone}</Typography></TableCell>
                    <TableCell><Typography>{requirement.email}</Typography></TableCell>
                    <TableCell><Typography>{requirement.subject}</Typography></TableCell>
                    <TableCell><Typography>{requirement.requirement}</Typography></TableCell>
                    {/* <TableCell>
                      {editableRequirement === requirement._id ? (
                        <>
                          <Button onClick={() => handleSave(requirement)} variant="contained" color="primary">Save</Button>
                          <Button onClick={handleCancel} variant="outlined">Cancel</Button>
                        </>
                      ) : (
                        <>
                          <Button onClick={() => handleEdit(requirement)} variant="contained" color="secondary">Edit</Button>
                          <Button onClick={() => handleDelete(requirement._id)} variant="outlined" color="error">Delete</Button>
                        </>
                      )}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>

      {/* <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">{alertMessage}</Alert>
      </Snackbar> */}
    </>
  );
}

export default RequirementTableAdmin;
