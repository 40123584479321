import React, { useEffect, useState } from "react";
import ProductAddForm from "../components/ProductAddForm";
import axios from "axios";
import {
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  Button,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";

function ProductTableAdmin() {
  const [products, setProducts] = useState([]);
  const [editableProduct, setEditableProduct] = useState(null);
  const [editedPrice, setEditedPrice] = useState(null);
  const [editedDiscount, setEditedDiscount] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  // const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = () => {
    axios
      .get(`https://api.sunrisediagnosticservice.com/api/products/all`)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the products!", error);
      });
  };

  const handleEdit = (product) => {
    setEditableProduct(product._id);
    setEditedPrice(product.price.value);
    setEditedDiscount(product.discount || 0);
  };

  const calculateDiscountedPrice = (price, discount) => {
    if (discount) {
      return price - price * (discount / 100);
    }
    return price;
  };

  const handleSave = (product) => {
    const discountedPrice = calculateDiscountedPrice(
      editedPrice,
      editedDiscount
    );

    const updatedProduct = {
      id: product._id,
      price: { value: discountedPrice },
      discount: editedDiscount,
    };

    axios
      .patch(`https://api.sunrisediagnosticservice.com/api/products/update`, updatedProduct)
      .then((response) => {
        setProducts((prevProducts) =>
          prevProducts.map((p) =>
            p._id === product._id
              ? { ...response.data, price: { value: discountedPrice } }
              : p
          )
        );
        setEditableProduct(null);
        setAlertMessage("Product updated successfully!");
        setOpenSnackbar(true);
      })
      .catch((error) => {
        console.error("There was an error updating the product!", error);
        setAlertMessage("Error updating the product!");
        setOpenSnackbar(true);
      });
  };

  const handleDelete = (productId) => {
    axios
      .delete(`https://api.sunrisediagnosticservice.com/api/products/delete`, {
        data: { id: productId },
      })
      .then(() => {
        setProducts(products.filter((p) => p._id !== productId));
        setAlertMessage("Product deleted successfully!");
        setOpenSnackbar(true);
      })
      .catch((error) => {
        console.error("There was an error deleting the product!", error);
        setAlertMessage("Error deleting the product!");
        setOpenSnackbar(true);
      });
  };

  const handleCancel = () => {
    setEditableProduct(null);
  };

  // const handleSearch = () => {
  //   axios
  //     .get(`http://13.201.223.150/api/products/search?query=${searchQuery}`)
  //     .then((response) => {
  //       setProducts(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("There was an error searching the products!", error);
  //     });
  // };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          backgroundColor: "#EFF5FE",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
        spacing={1}
      >
        <Stack
          direction="row"
          sx={{ width: "95%", justifyContent: "flex-end" }}
        >
          <ProductAddForm />
        </Stack>

        <Stack sx={{ width: "95%", height: "88%" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#0D65B7" }}>
                  <TableCell
                    sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                  >
                    Product Name
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                  >
                    Unit Price
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                  >
                    Brand
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                  >
                    Availability
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                  >
                    Discount
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => {
                  const availability = product.availability
                    ? "In Stock"
                    : "Out of Stock";
                  return (
                    <TableRow key={product._id} sx={{ height: "7rem" }}>
                      <TableCell component="th" scope="row">
                        <Stack direction="row" spacing={1}>
                          <img
                            style={{ width: "7rem", height: "6rem" }}
                            src={`https://api.sunrisediagnosticservice.com${product.images[0]}`}
                            alt={product.productName}
                          />
                          <Stack>
                            <Typography>{product.model}</Typography>
                            <Typography>{product.productName}</Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        {editableProduct === product._id ? (
                          <TextField
                            value={editedPrice}
                            onChange={(e) => setEditedPrice(e.target.value)}
                          />
                        ) : (
                          <Typography sx={{ fontWeight: "600" }}>
                            ₹ {product?.price?.value}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>{product.brand}</TableCell>
                      <TableCell>
                        <Typography>{availability}</Typography>
                      </TableCell>
                      <TableCell>
                        {editableProduct === product._id ? (
                          <TextField
                            value={editedDiscount}
                            onChange={(e) => setEditedDiscount(e.target.value)}
                          />
                        ) : (
                          <Typography>{product.discount || "N/A"}</Typography>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          height: "6rem",
                        }}
                      >
                        {editableProduct === product._id ? (
                          <>
                            <Button
                              onClick={() => handleSave(product)}
                              variant="contained"
                              color="primary"
                            >
                              Save
                            </Button>
                            <Button onClick={handleCancel} variant="outlined">
                              Cancel
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              onClick={() => handleEdit(product)}
                              variant="contained"
                              color="secondary"
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => handleDelete(product._id)}
                              variant="outlined"
                              color="error"
                            >
                              Delete
                            </Button>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ProductTableAdmin;
