import React from "react";
import { Stack, List, ListItem, ListItemIcon, ListItemText, Typography, Box } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People"; // Icon for users
import logo from "../assets/logo.png";

const Sidebar = ({ setSelectedOption }) => {
  return (
    <Stack
      variant="permanent"
      sx={{
        backgroundColor: "#1976d2",
        height: "100%",
        width: "100%",
        flexShrink: 0,
      }}
    >
      <Box
        sx={{
          display: "flex-col",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
          backgroundColor: "#1976d2",
          color: "white",
        }}
      >
        <img src={logo} alt="Logo" style={{ height: "50px", marginRight: "8px" }} />
        <Typography variant="h10" component="div">
          Sunrise Diagnostic Services
        </Typography>
      </Box>

      <List sx={{ backgroundColor: "#1976d2" }}>
        <ListItem button onClick={() => setSelectedOption("products")}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText sx={{ color: "white" }} primary="Products" />
        </ListItem>
        <ListItem button onClick={() => setSelectedOption("orders")}>
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText sx={{ color: "white" }} primary="Orders" />
        </ListItem>
        <ListItem button onClick={() => setSelectedOption("users")}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText sx={{ color: "white" }} primary="Users" />
        </ListItem>
        <ListItem button onClick={() => setSelectedOption("requirements")}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText sx={{ color: "white" }} primary="Requirements" />
        </ListItem>
      </List>
    </Stack>
  );
};

export default Sidebar;
