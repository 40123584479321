// File: src/pages/AdminPage.js
import React, { useState } from "react";
import { Stack, Button } from "@mui/material";
import Sidebar from "../components/Sidebar";
import ProductTableAdmin from "../components/ProductTableAdmin";
import OrderTableAdmin from "../components/OrderTableAdmin";
import UserTableAdmin from "../components/UserTableAdmin";
import RequirementTableAdmin from "../components/RequirementTableAdmin"; // Import the new component
import { useNavigate } from "react-router-dom";

function AdminPage() {
  const [selectedOption, setSelectedOption] = useState("products");
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("admin");
    localStorage.removeItem("isAdmin");
    navigate("/admin");
  };

  return (
    <Stack direction="row" sx={{ width: "100%", height: "100vh" }}>
      <Stack sx={{ width: "20%", height: "100%" }}>
        <Sidebar setSelectedOption={setSelectedOption} />
      </Stack>

      <Stack sx={{ width: "80%" }}>
        <Button
          onClick={handleLogout}
          sx={{ width: "5%", marginLeft: "2.2rem", marginTop: "1rem", backgroundColor: "#8f0200", color: "white", position: "absolute", borderRadius: 3 }}
        >
          Logout
        </Button>
        {selectedOption === "products" && <ProductTableAdmin />}
        {selectedOption === "orders" && <OrderTableAdmin />}
        {selectedOption === "users" && <UserTableAdmin />}
        {selectedOption === "requirements" && <RequirementTableAdmin />} {/* Show Requirement Table */}
      </Stack>
    </Stack>
  );
}

export default AdminPage;
