import React from "react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import ST200 from "../assets/ST-200.png"; // Import the default image
import Maglumi800 from "../assets/maglumi-800-1.png";
import SACCA from "../assets/sacca.png";
import BA88A from "../assets/ba88a.png";
import BC20S from "../assets/bc20s.png";
import BC5130 from "../assets/bc5130.png";
import BS240 from "../assets/bs240.png";
import BC20 from "../assets/bc20.png";
import BS230 from "../assets/bs230.png";
import CL900I from "../assets/cl900i.png";
import BC6000 from "../assets/bc6000.png";
import BS240E from "../assets/bs24oe.png";
import BC700 from "../assets/bc-700.png";
function ProductBox({
  id,
  img,
  ProNum,
  ProTitle,
  ProSubTitle,
  brandName,
  images,
  discount,
  price,
  model,
}) {
  const navigate = useNavigate();

  const handleClick = () => {
    const token = localStorage.getItem("jwtToken"); // Check for token
    if (token) {
      // If token exists, navigate to the product details page
      navigate(`/product/${id}`);
    } else {
      // If no token, navigate to the signup page
      navigate("/signup");
    }
  };

  // Image mapping based on model name
  const imageMapping = {
    "ST-200": ST200,
    "Maglumi 800": Maglumi800,
    "BA-88A": BA88A,
    "BC-20s": BC20S,
    "BC-5130": BC5130,
    "BS-240": BS240,
    "BC-20": BC20,
    "BS-230": BS230,
    "CL-900i": CL900I,
    "BC-6000": BC6000,
    "BS-240E": BS240E,
    "SA-CCA": SACCA,
    "BC-700": BC700,
  };

  // Default to the first image if available, otherwise use the mapped image or fallback
  const serverUrl = `https://api.sunrisediagnosticservice.com`;
  const imageUrl =
    images && images.length > 0
      ? `${serverUrl}${images[0]}`
      : imageMapping[ProNum] || ""; // Use the mapped image or leave it blank

  console.log(imageUrl); // Log the selected image URL for debugging

  return (
    <Card
      sx={{
        width: {
          xs: "100%", // 100% width on extra-small screens (mobile devices)
          sm: "80%", // 80% width on small screens (tablets)
          md: "20vw", // 20% width on medium screens (small laptops)
          lg: "17.5vw", // 17.5% width on large screens (desktops)
        },
        height: "auto",
        borderRadius: "0.7rem",
      }}
      onClick={handleClick} // Handle click event
    >
      {/* <CardMedia
        sx={{
          height: "18rem",
          width: "100%",
          objectFit: "fit", // Ensures the image is contained within the bounds
        }}
        image={imageUrl}
        title={ProTitle}
      /> */}
      <img
        src={imageMapping[ProNum]}
        style={{
          height: "18rem",
          width: "100%",
          objectFit: "fit", // Ensures the image is contained within the bounds
        }}
        alt=""
      />
      <Stack sx={{ padding: "1rem" }}>
        <Typography
          sx={{ color: "#0D65B7", fontWeight: "500", fontSize: "14px" }}
        >
          {ProNum}
        </Typography>
        <Typography
          sx={{
            color: "#0D65B7",
            fontWeight: "500",
            fontSize: "14px",
            marginTop: -0.5,
          }}
        >
          {ProTitle}
        </Typography>
        <Typography
          sx={{
            color: "#0D65B7",
            fontWeight: "500",
            fontSize: "14px",
            marginTop: -0.5,
          }}
        >
          {ProSubTitle}
        </Typography>
        <Typography
          sx={{
            color: "#707579",
            fontWeight: "500",
            fontSize: "14px",
            marginTop: 2,
          }}
        >
          Brand: {brandName}
        </Typography>
        <Typography
          sx={{
            color: "#e99804",
            fontWeight: "500",
            fontSize: "15px",
            marginTop: 2,
          }}
        >
          ₹{price} gst included
        </Typography>
        {discount > 0 && (
          <Typography
            sx={{
              color: "#058dc3",
              fontWeight: "500",
              fontSize: "14px",
              // marginTop: 2,
            }}
          >
            {discount}% off
          </Typography>
        )}
      </Stack>
    </Card>
  );
}

export default ProductBox;
