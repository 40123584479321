import { Stack, Typography, Button, TextField, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import logo from "../assets/logo.png";
import ST200 from "../assets/ST-200.png";
import Maglumi800 from "../assets/maglumi-800-1.png";
import SACCA from "../assets/sacca.png";
import BA88A from "../assets/ba88a.png";
import BC20S from "../assets/bc20s.png";
import BC5130 from "../assets/bc5130.png";
import BS240 from "../assets/bs240.png";
import BC20 from "../assets/bc20.png";
import BS230 from "../assets/bs230.png";
import CL900I from "../assets/cl900i.png";
import BC6000 from "../assets/bc6000.png";
import BS240E from "../assets/bs24oe.png";
function CartScreen() {
  const [orders, setOrders] = useState([]);
  const [cartDetails, setCartDetails] = useState([]);
  const [error, setError] = useState(null);
  const [address, setAddress] = useState(""); // New state for address
  const [paymentMethod, setPaymentMethod] = useState("cod"); // New state for payment method

  // Example: Replace with actual user ID from authentication/session
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          `https://api.sunrisediagnosticservice.com/api/orders/all?userId=${userId}` // Pass userId in query params
        );
        setOrders(response.data);
         const productIds = response.data.flatMap((order) =>
          order.products.map((product) => product.product._id || product.product) // Use the appropriate field for ID extraction
        );

        const productDetailsResponses = await Promise.all(
          productIds.map((id) =>
            axios.get(
              `https://api.sunrisediagnosticservice.com/api/products/${id}`
            )
          )
        );

        const fetchedProducts = productDetailsResponses.map((res) => res.data);
        setCartDetails(fetchedProducts);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setError("Failed to load cart items.");
      }
    };
    fetchOrders();
  }, [userId]);

  const handleQuantityChange = async (orderId, productId, change) => {
    try {
      const currentOrder = orders.find((order) => order._id === orderId);
      if (!currentOrder) {
        console.error("Order not found");
        return;
      }

      const currentProduct = currentOrder.products.find(
        (product) => product.product === productId
      );
      if (!currentProduct) {
        console.error("Product not found");
        return;
      }

      const updatedQuantity = Math.max(currentProduct.quantity + change, 0);

      // Remove or update product quantity in the order
      const updatedOrders = orders
        .map((order) =>
          order._id === orderId
            ? {
                ...order,
                products: order.products
                  .map((product) =>
                    product.product === productId
                      ? {
                          ...product,
                          quantity: updatedQuantity,
                        }
                      : product
                  )
                  .filter((product) => product.quantity > 0), // Remove products with 0 quantity
              }
            : order
        )
        .filter((order) => order.products.length > 0); // Remove orders with no products

      setOrders(updatedOrders);

      if (updatedQuantity === 0) {
        await axios.delete(
          `https://api.sunrisediagnosticservice.com/api/orders/removeitem`,
          {
            data: { orderId, productId },
          }
        );
      } else {
        await axios.patch(
          `https://api.sunrisediagnosticservice.com/api/orders/update`,
          {
            id: orderId,
            products: updatedOrders.find((order) => order._id === orderId)
              .products,
          }
        );
      }
    } catch (error) {
      console.error("Error updating order:", error);
      setError("Failed to update cart items.");
    }
  };

  const findProductDetails = (productId) => {
    return cartDetails.find((product) => product?._id === productId) || {};
  };

  // Calculating the total amount
  const amount = orders.reduce(
    (total, order) =>
      total +
      order.products.reduce((subTotal, product) => {
        const productDetails = findProductDetails(product.product);
        return (
          subTotal + product.quantity * (productDetails?.price?.value || 0)
        );
      }, 0),
    0
  );

  const currency = "INR";

  // Razorpay Payment Handler
  const paymentHandler = async (e) => {
    e.preventDefault();
    if (!address.trim()) {
      alert("Please fill in your address!");
      return;
    }
    if (paymentMethod === "cod") {
      await axios.patch(`https://api.sunrisediagnosticservice.com/api/orders/update`, {
        id: orders[0]._id,
        address,
        amount,
        paymentMethod: "Cash on Delivery",
        status: 'Done', // Initial status
      });
      alert("Order placed successfully!");
    } else {
      const amountInPaise = Math.round(amount * 100);
      const response = await axios.post(
        `https://api.sunrisediagnosticservice.com/api/orders/order-payment`,
        { amount: amountInPaise }
      );
      const order = response.data;

      var options = {
        key: "rzp_test_4lbZFlzRgDKcCl",
        amount: amountInPaise,
        currency,
        name: "Acme Corp",
        description: "Test Transaction",
        image: logo,
        order_id: order.id,
        handler: async function (response) {
          await axios.post(`https://api.sunrisediagnosticservice.com/api/orders/validate`, response);
          alert("Order placed successfully!");
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
    }
  };
  const imageMapping = {
    "ST-200": ST200,
    "Maglumi 800": Maglumi800,
    "BA-88A": BA88A,
    "BC-20s": BC20S,
    "BC-5130": BC5130,
    "BS-240": BS240,
    "BC-20": BC20,
    "BS-230": BS230,
    "CL-900i": CL900I,
    "BC-6000": BC6000,
    "BS-240E": BS240E,
    "SA-CCA": SACCA,
  };

  return (
    <Stack
      sx={{
        width: "100%",
        backgroundColor: "#EFF5FE",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontWeight: "600", fontSize: "24px", margin: 3 }}>
        ADD TO CART
      </Typography>
      <Stack
        sx={{
          width: "80%",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Typography
          sx={{ fontWeight: "500", fontSize: "19px", margin: "11px 0" }}
        >
          Shopping Cart :{" "}
          {orders.reduce((total, order) => total + order.products.length, 0)}{" "}
          Items
        </Typography>
        <Stack
          direction="row"
          sx={{ width: "100%", justifyContent: "space-between" }}
        >
          <Stack sx={{ width: "75%" }}>
            {error ? (
              <Typography color="error">{error}</Typography>
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#0D65B7" }}>
                      <TableCell
                        sx={{
                          color: "white",
                          fontSize: "17px",
                          fontWeight: "500",
                        }}
                      >
                        Product Name
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontSize: "17px",
                          fontWeight: "500",
                        }}
                      >
                        Unit Price
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontSize: "17px",
                          fontWeight: "500",
                        }}
                      >
                        Quantity
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontSize: "17px",
                          fontWeight: "500",
                        }}
                      >
                        Availability
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontSize: "17px",
                          fontWeight: "500",
                        }}
                      >
                        SubTotal
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((order) =>
                      order.products.map((product) => {
                        const productDetails = findProductDetails(
                          product.product._id
                        );
                        const productImage = productDetails?.images?.[0]
                        ? `https://api.sunrisediagnosticservice.com/${productDetails.images[0]}`
                        : imageMapping[productDetails?.model] || ""; // Use mapped image if available

                        return (
                          <TableRow
                            key={product.product}
                            sx={{ height: "7rem" }}
                          >
                            <TableCell component="th" scope="row">
                              <Stack direction="row" spacing={1}>
                                <img
                                  style={{ width: "6.5rem", height: "6rem" }}
                                  src={productImage}

                                  alt={productDetails?.name}
                                />
                                <Stack>
                                  <Typography>
                                    {productDetails.model}
                                  </Typography>
                                  <Typography>
                                    {productDetails.productName}
                                  </Typography>
                                  <Typography>
                                    {productDetails.brand}
                                  </Typography>
                                  <Typography>
                                    {productDetails.display}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontWeight: "600" }}>
                                ₹ {productDetails?.price?.value.toFixed(2)}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Stack
                                direction="row"
                                sx={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  border: "0.8px solid black",
                                  borderRadius: 1,
                                }}
                              >
                                <Button
                                  onClick={() =>
                                    handleQuantityChange(
                                      order._id,
                                      product.product,
                                      -1
                                    )
                                  }
                                >
                                  -
                                </Button>
                                <Typography
                                  sx={{ width: "2rem", textAlign: "center" }}
                                >
                                  {product.quantity}
                                </Typography>
                                <Button
                                  onClick={() =>
                                    handleQuantityChange(
                                      order._id,
                                      product.product,
                                      1
                                    )
                                  }
                                >
                                  +
                                </Button>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Typography>
                                {productDetails.availability === true
                                  ? "In Stock"
                                  : "Out of Stock"}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>
                                ₹{" "}
                                {(
                                  product.quantity *
                                  (productDetails?.price?.value || 0)
                                ).toFixed(2)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Stack>
          <Stack
            sx={{
              width: "25%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                width: "90%",
                backgroundColor: "white",
                borderRadius: 1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Stack
                sx={{
                  borderBottom: "0.5px solid black",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "18px",
                    width: "100%",
                    padding: "0.8rem 0",
                    textAlign: "center",
                  }}
                >
                  SUMMARY
                </Typography>
              </Stack>
              <Stack
                sx={{
                  padding: 2,
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <Typography sx={{ marginBottom: "0.8rem", fontWeight: "500" }}>
                  TOTAL PRICE: ₹ {amount.toFixed(2)}
                </Typography>
                <Stack sx={{ width: "80%" }}>
                  <TextField
                    label="Enter Address"
                    fullWidth
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    sx={{ marginBottom: 2 }}
                  />
                  <RadioGroup
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    defaultValue="cod" // Make sure to set defaultValue here

                  >
                    <FormControlLabel
                      value="online"
                      control={<Radio />}
                      label="Online Payment"
                      disabled
                    />
                    <FormControlLabel
                      value="cod"
                      control={<Radio />}
                      label="Cash on Delivery"
                      aria-selected
                    />
                  </RadioGroup>
                  <Button
                    onClick={paymentHandler}
                    sx={{
                      backgroundColor: "#0D65B7",
                      color: "white",
                      width: "100%",
                    }}
                  >
                    Place Order
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default CartScreen;
