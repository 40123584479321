import React, { useEffect, useState } from "react";
import ProductBox from "./ProductBox";
import { Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";

function LabEqp() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.sunrisediagnosticservice.com/api/products/all`) // Fetch all products
      .then((response) => {
        const allProducts = response.data;
        console.log(allProducts, "abcc");
        // Filter products where usageApplication is 'Lab Eq'
        // const labEquipments = allProducts.filter(product => product.brand === "");
        // Limit to 4 products
        const limitedProducts = allProducts.slice(0, 4);
        setProducts(limitedProducts);
      })
      .catch((error) => {
        console.error("There was an error fetching the products!", error);
      });
  }, []);

  return (
    <Stack
      spacing={2}
      sx={{
        width: "100%",
        padding: "2rem 0",
        backgroundColor: "#EFF5FE",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Stack
        direction="row"
        sx={{
          width: "88%",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" }, // Stacks vertically on small screens
          textAlign: { xs: "center", sm: "left" },
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
          Laboratory Equipments
        </Typography>
        <Link to="/shop">View All</Link>
      </Stack>

      <Grid
        container
        spacing={2}
        sx={{
          width: "95%",
          justifyContent: "center",
          padding: "0 2rem",
        }}
      >
        {products &&
          products.map((product) => (
            <Grid
              item
              key={product.id}
              xs={12}
              sm={6} // 1 item per row on small screens
              md={3} // 3 items per row on medium screens
              lg={3} // 4 items per row on large screens
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <ProductBox
                id={product._id}
                img={product.img} // Provide a default image if not available
                ProNum={product.model}
                ProTitle={product.productName}
                ProSubTitle={product.ProSubTitle}
                brandName={product.brand}
                price={product.price.value}
                images={product.images}
                // discount={product.discount}
              />
            </Grid>
          ))}
      </Grid>
    </Stack>
  );
}

export default LabEqp;
