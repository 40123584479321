import { Stack, Typography, Input, Button } from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import dots from "../assets/dots.png";

function AdminAuth() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async () => {
        setError(''); // Clear any previous errors
        try {
            const payload = { email, password };
            console.log('Login Payload:', payload); // Log payload for debugging

            const response = await axios.post(`https://api.sunrisediagnosticservice.com/api/admin/login`, payload);
            console.log('Login Response:', response); // Log full response for debugging

            const { token } = response.data;

            // Save token and admin status to localStorage
            localStorage.setItem('jwtToken', token);
            localStorage.setItem('isAdmin', true);

            // Navigate to the admin dashboard
            navigate('/admin-page');

        } catch (err) {
            // Only show error if response has no token and user data
            console.error('Error during login:', err.response?.data || err.message);
            setError(err.response?.data?.message || "Login failed. Please try again.");
        }
    };

    // Handler for "Back to Home" button
    const goToHomePage = () => {
        navigate('/home'); // Navigate to the home route
    };

    return (
        <Stack sx={{ width: "100%", height: "100vh", justifyContent: "center", alignItems: "center", backgroundColor: "#DFF0FF" }}>
            <Stack direction="row" sx={{ width: "65%", height: "37rem", borderRadius: 5 }}>
                <Stack sx={{ width: "50%", height: "100%", backgroundColor: "#0D65B7", borderRadius: "1rem 0 0 1rem" }}>
                    {/* Left panel with logo and text */}
                    <Stack sx={{ width: "100%", height: "20%", justifyContent: "center", alignItems: "flex-start", padding: "0 3rem" }}>
                        <Stack sx={{ width: "3.5rem", height: "3.5rem" }}>
                            <img src={dots} alt="design" />
                        </Stack>
                    </Stack>
                    <Stack sx={{ width: "100%", height: "30%", justifyContent: "center", alignItems: "center", position: "relative", top: -50 }}>
                        <Stack sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
                            <Stack sx={{ width: "8rem", position: "relative", right: 20 }}>
                                <img src={logo} alt="logo" />
                            </Stack>
                        </Stack>
                        <Stack sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
                            <Typography sx={{ fontSize: "16px", color: "white" }}>
                                <Typography sx={{ fontSize: "26px" }}>Sunrise</Typography>
                                Diagnostic Services
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack sx={{ height: "30%", padding: "0 3rem", justifyContent: "center" }}>
                        <Typography sx={{ fontSize: "20px", color: "white" }}>Welcome to...</Typography>
                        <Typography sx={{ fontSize: "14px", color: "white" }}>
                            "Sunrise Diagnostic Services, where cutting-edge diagnostic equipment meets precision and reliability..."
                        </Typography>
                    </Stack>
                    <Stack sx={{ width: "100%", height: "20%", justifyContent: "center", alignItems: "flex-end" }}>
                        <Stack sx={{ width: "3.5rem", height: "3.5rem", margin: "0 3rem" }}>
                            <img src={dots} alt="design" />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack sx={{ width: "50%", height: "100%", backgroundColor: "white", borderRadius: "0rem 1rem 1rem 0rem", justifyContent: "center", alignItems: "center" }}>
                    <Stack sx={{ width: "70%" }}>
                        <Stack>
                            <Typography sx={{ fontSize: "26px", fontWeight: "600" }}>Admin Sign In</Typography>
                            <Typography sx={{ fontSize: "13px", color: "#656C71" }}>
                                Enter your details to sign in to your admin account
                            </Typography>
                        </Stack>
                        <Stack>
                            <Typography>Email Address</Typography>
                            <Input
                                sx={{ width: "100%", height: "2.4rem", border: "0.3px solid black", margin: "8px 0 25px", backgroundColor: "#F3F5F9" }}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Stack>
                        <Stack>
                            <Typography>Password</Typography>
                            <Input
                                sx={{ width: "100%", height: "2.4rem", border: "0.3px solid black", margin: "8px 0 25px", backgroundColor: "#F3F5F9" }}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                            />
                        </Stack>

                        {error && (
                            <Typography sx={{ color: 'red' }}>
                                {error}
                            </Typography>
                        )}

                        <Stack>
                            <Button
                                sx={{ width: "100%", height: "2.4rem", backgroundColor: "#0D65B7", color: "white" }}
                                onClick={handleSubmit}
                            >
                                SIGN IN
                            </Button>
                        </Stack>

                        {/* "Back to Home" Button */}
                        <Stack sx={{ marginTop: "1rem" }}>
                            <Button
                                sx={{ width: "100%", height: "2.4rem", backgroundColor: "#656C71", color: "white" ,
                                    ':hover': {
                                        backgroundColor: "#0D65B7", // Keep background color on hover
                                        boxShadow: 'none' // Optional: Remove shadow if present on hover
                                    }
                                }}
                                onClick={goToHomePage} // Navigate to home
                            >
                                Back to Home
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default AdminAuth;
