import React from 'react';
import { Stack, Typography } from '@mui/material';
import brand1 from '../assets/brand1.png'
import brand2 from '../assets/brand2.png'
import brand3 from '../assets/brand3.png'
import brand4 from '../assets/brand4.png'
import brand5 from '../assets/brand5.png'
import brand6 from '../assets/brand6.png'

function TopBrands() {
    const brands = [
        {
            id: 1,
            img: brand1,

        },
        {
            id: 2,
            img: brand2,

        },
        {
            id: 3,
            img: brand3,

        },
        {
            id: 4,
            img: brand4,

        },
        {
            id: 5,
            img: brand5,

        },
        {
            id: 6,
            img: brand6,

        },
    ];

    return (
        <Stack spacing={2} sx={{padding:"40px 0",  backgroundColor:"#EFF5FE" ,alignItems:"center"
        }}>
            <Stack sx={{width: "85%"}}>
            <Typography sx={{fontSize:"24px",fontWeight:"500",                  textAlign: { xs: "center", sm: "left" },
}}>
                Top Brands
            </Typography>
            </Stack>
            <Stack  sx={{
                width: "100%", flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
                // padding: "2rem 0"

            }}>

                <Stack sx={{
                    width: {xs:"90%",md:"88%"},
                    height: "auto",
                    flexDirection: "row",
                    // gap: "1rem",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#D3E4F4",
                    borderRadius: "0.5rem",
                    flexWrap:"wrap",
                    paddingTop:{xs:"10px",md:"2rem"},
                    paddingBottom:{xs:"10px",md:"2rem"}

                }}>

                    {brands.map(brand => (
                        <Stack key={brand.id} sx={{width:"18rem",margin:"1.5rem 6rem"}}>
                            <img src={brand.img} alt=""/>
                        </Stack>
                    ))}
                </Stack>
            </Stack>
        </Stack>
    );
}

export default TopBrands;
