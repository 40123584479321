import React, { useState, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ProductBox from '../components/ProductBox';
import axios from 'axios';

function AllProducts() {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [discountFilter, setDiscountFilter] = useState(false);
    const [priceOrder, setPriceOrder] = useState("");

    useEffect(() => {
        axios.get(`https://api.sunrisediagnosticservice.com/api/products/all`)
            .then(response => {
                const fetchedProducts = response.data;

                // Sort products based on the rank in increasing order
                const sortedProducts = fetchedProducts.sort((a, b) => {
                    return (a.rank !== undefined ? a.rank : Infinity) - (b.rank !== undefined ? b.rank : Infinity);
                });

                setProducts(sortedProducts);
                setFilteredProducts(sortedProducts);
            })
            .catch(error => {
                console.error('There was an error fetching the products!', error);
            });
    }, []);

    useEffect(() => {
        applyFilters();
    }, [selectedBrands, discountFilter, priceOrder]);

    const handleBrandChange = (event) => {
        const { value, checked } = event.target;
        setSelectedBrands(prev => checked ? [...prev, value] : prev.filter(brand => brand !== value));
    };

    const handleDiscountChange = (event) => {
        setDiscountFilter(event.target.checked);
    };

    const handlePriceChange = (event) => {
        setPriceOrder(event.target.value);
    };

    const applyFilters = () => {
        let filtered = [...products];

        // Filter by brands
        if (selectedBrands.length > 0) {
            filtered = filtered.filter(product => selectedBrands.includes(product.brand));
        }

        // Filter by discount
        if (discountFilter) {
            filtered = filtered.filter(product => product.discount > 0);
        }

        // Sort by rank and then place products without a rank at the end
        filtered.sort((a, b) => {
            if (a.rank !== undefined && b.rank !== undefined) {
                return a.rank - b.rank; // Sort by rank
            }
            if (a.rank === undefined && b.rank === undefined) {
                return 0; // Keep the order of products without rank unchanged
            }
            return a.rank === undefined ? 1 : -1; // Place products without rank at the end
        });

        // Sort by price if the price order is set
        if (priceOrder === "low-to-high") {
            filtered.sort((a, b) => a.price.value - b.price.value);
        } else if (priceOrder === "high-to-low") {
            filtered.sort((a, b) => b.price.value - a.price.value);
        }

        setFilteredProducts(filtered);
    };

    return (
        <Stack sx={{ width: "100%", justifyContent: "center", alignItems: "center", backgroundColor: "#EFF5FE" }}>
            <Typography sx={{ fontWeight: "600", fontSize: "24px", margin: 3 }}>ALL PRODUCTS</Typography>
            <Stack direction={{ xs: "column", md: "row" }} sx={{ width: "85%", justifyContent: "space-between", alignItems: { xs: "center", md: "flex-start" } }}>
                <Stack sx={{ width: { xs: "90%", md: "30%" }, justifyContent: "flex-start" }}>
                    <Stack sx={{ width: "90%", padding: 1.5, alignItems: "center" }}>
                        <Typography sx={{ width: "90%", fontWeight: "600", fontSize: "19px" }}>Products Filter by</Typography>
                        <Stack sx={{ width: "100%" }}>
                            <Accordion sx={{ backgroundColor: "transparent", boxShadow: "0" }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                                    Brands
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: "0 2rem" }}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox value="Mindray" onChange={handleBrandChange} />} label="Mindray" />
                                        <FormControlLabel control={<Checkbox value="Sensa Core" onChange={handleBrandChange} />} label="Sensa Core" />
                                        <FormControlLabel control={<Checkbox value="Spinreact" onChange={handleBrandChange} />} label="Spinreact" />
                                        <FormControlLabel control={<Checkbox value="Diatron" onChange={handleBrandChange} />} label="Diatron" />
                                        <FormControlLabel control={<Checkbox value="Abbott" onChange={handleBrandChange} />} label="Abbott" />
                                        <FormControlLabel control={<Checkbox value="Lifotronic" onChange={handleBrandChange} />} label="Lifotronic" />
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion sx={{ backgroundColor: "transparent", boxShadow: "0" }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
                                    Discount
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: "0 2rem" }}>
                                    <FormControlLabel control={<Checkbox onChange={handleDiscountChange} />} label="Has Discount" />
                                </AccordionDetails>
                            </Accordion>

                            <Accordion sx={{ backgroundColor: "transparent", boxShadow: "0" }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
                                    Price
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: "0 2rem" }}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox value="low-to-high" onChange={handlePriceChange} checked={priceOrder === "low-to-high"} />} label="Low to High" />
                                        <FormControlLabel control={<Checkbox value="high-to-low" onChange={handlePriceChange} checked={priceOrder === "high-to-low"} />} label="High to Low" />
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack sx={{ width: "70%", height: "100%" }}>
                    <Stack sx={{ width: "100%", flexDirection: "row", gap: "2rem", flexWrap: "wrap" }}>
                        {filteredProducts?.map(product => (
                            <ProductBox
                                key={product._id}
                                id={product._id}
                                img={product.img}
                                ProNum={product.model}
                                ProTitle={product.productName}
                                ProSubTitle={product.ProSubTitle}
                                brandName={product.brand}
                                price={product.price.value}
                                images={product.images}
                                discount={product.discount}
                            />
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default AllProducts;
