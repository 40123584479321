import React from "react";
import Estimates from "../components/Estimates";
import Header from "../components/Header";
import LabEqp from "../components/LabEqp";
// import OfferProducts from "../components/OfferProducts";
import Testimonial from "../components/Testimonial";
import TopBrands from "../components/TopBrands";
import Vision from "../components/Vision";

// import BestSellingProducts from "../components/BestSellingProducts";
import DiscountedProducts from "../components/DiscountedProducts";
import NewAddOns from "../components/NewAddOns";
// import Office from "../components/Office";
// import Team from "../components/Team";

function HomePage() {
  return (
    <div>
      <Header />
 {/* <ProductAddForm /> */}
      <Vision />
      <LabEqp />
      {/* <Office /> */}
      <NewAddOns />
      {/* <BestSellingProducts /> */}
      {/* <Team /> */}
      <Estimates />
      <TopBrands />
      {/* <OfferProducts /> */}
      <DiscountedProducts />

      <Testimonial />
   </div>
  );
}

export default HomePage;
