import { Button,  Stack, Typography, Grid } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom"; // Import from react-router-dom for navigation
// import googlePlay from "../assets/googlePlay.png";
import shippingImg from "../assets/shipping.png";
import cod from "../assets/cod.png";
import satis from "../assets/satis.png";
import trans from "../assets/trans.png";

function Footer() {
  const footerLinks = [
    {
      id: 1,
      title: "Go to",
      link1: "Shop",
      link2: "Signup",
      link3: "Home",
      // link4: "Checkout"
    },
  ];

  const shipping = [
    {
      id: 1,
      title: "Free Shipping",
      subTitle: "All India Fast Delivery by Leading Courier Partners",
      image: shippingImg,
    },
    {
      id: 2,
      title: "COD Available",
      subTitle: "For Cart Value between ₹500 to ₹5000",
      image: cod,
    },
    {
      id: 3,
      title: "Satisfaction Guarantee",
      subTitle: "Finest Quality Products",
      image: satis,
    },
    {
      id: 4,
      title: "100% Secure Transaction",
      subTitle: "Secure SSL Encryption",
      image: trans,
    },
  ];

  const navigate = useNavigate(); // Use react-router-dom's useNavigate for programmatic navigation

  // Handle yellow button click to navigate to admin page
  const handleAdminButtonClick = () => {
    navigate("/admin");
  };

  return (
    <>
      <Stack
        sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
      >
        <Grid
          container
          spacing={3}
          sx={{ width: "100%", height: "auto", padding: "1rem 0" }}
        >
          {shipping.map((ship) => (
            <Grid item key={ship.id} xs={12} sm={6} md={6} lg={3}>
              <Stack
                sx={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                direction="row"
                spacing={2}
              >
                <img
                  src={ship.image}
                  alt=""
                  style={{ width: "4rem", height: "3rem" }}
                />
                <Stack
                  sx={{
                    width: "12rem",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                  spacing={0.5}
                >
                  <Typography
                    sx={{
                      fontSize: "17px",
                      fontWeight: "700",
                      color: "#0D65B7",
                    }}
                  >
                    {ship.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#010B14",
                      width: "75%",
                    }}
                  >
                    {ship.subTitle}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>

      <Stack
        sx={{
          width: "100%",
          height: "auto",
          backgroundColor: "#0D65B7",
          justifyContent: "center",
          alignItems: "center",
          padding: "1rem 0",
        }}
        spacing={5}
      >
        <Grid
          container
          spacing={2}
          sx={{
            width: "75%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {footerLinks.map((link) => (
            <Grid item key={link.id} xs={12} sm={6} md={3} lg={2}>
              <Stack
                sx={{ justifyContent: "center", alignItems: "flex-start" }}
              >
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: "700",
                    textAlign: "left",
                    color: "#F2F9FC",
                  }}
                >
                  {link.title}
                </Typography>
                <Stack
                  direction="row"
                  sx={{ width: "100%", mt: 1 }}
                  spacing={3}
                >
                  <Link
                    to="/shop"
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#F2F9FC",
                      textDecoration: "none",
                      textAlign: "left",
                    }}
                  >
                    {link.link1}
                  </Link>
                  <Link
                    to="/signup"
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#F2F9FC",
                      textDecoration: "none",
                      textAlign: "left",
                    }}
                  >
                    {link.link2}
                  </Link>
                  <Link
                    to="/home"
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#F2F9FC",
                      textDecoration: "none",
                      textAlign: "left",
                    }}
                  >
                    {link.link3}
                  </Link>
                  {/* Yellow Button - Navigate to Admin Page */}
                  <Button
                    onDoubleClick={handleAdminButtonClick} // Trigger navigation on double-click
                    sx={{
                      
                      height: 30,
                    //   backgroundColor: "yellow",
                    //   ml: -20,
                    }}
                  >
                    <svg
                      // key={index}
                      width="1cm"
                      height="1m"
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      viewBox="200 0 500 500"
                    >
                      <polygon
                        fill={"gold"} // Fill gold for stars less than or equal to rating
                        stroke="gray"
                        strokeWidth="10"
                        points="350,75  379,161 469,161 397,215 423,301 350,250 277,301 303,215 231,161 321,161"
                      />
                    </svg>{" "}
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          ))}

          {/* <Grid item xs={12} sm={6} md={3} lg={2.5}>
            <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: "21px",
                  fontWeight: "700",
                  textAlign: "left",
                  color: "#F2F9FC",
                }}
              >
                EXPERIENCE APP ON MOBILE
              </Typography>
              <Stack direction="row" sx={{ width: "100%", mt: 1 }} spacing={2}>
                <Button>
                  <img
                    src={googlePlay}
                    style={{ width: "7rem", height: "2.8rem" }}
                    alt=""
                  />
                </Button>
                <Button>
                  <img
                    src={googlePlay}
                    style={{ width: "7rem", height: "2.8rem" }}
                    alt=""
                  />
                </Button>
              </Stack>
            </Stack>
          </Grid> */}
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            width: "75%",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Grid item xs={12} sm={6} md={5}>
            <Stack
              sx={{ justifyContent: "center", alignItems: "flex-start" }}
              spacing={2.5}
            >
              <Typography
                sx={{ fontWeight: "500", fontSize: "16px", color: "#F2F9FC" }}
              >
                Register for Special Discounts & Offers!
              </Typography>
              <Typography
                sx={{ fontWeight: "400", fontSize: "16px", color: "#ABB3B8" }}
              >
                Will be used in accordance with our
                <Link
                  to="/privacy-policy"
                  style={{ color: "#E68200", textDecoration: "none" }}
                >
                  {" "}
                  Privacy Policy
                </Link>
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={5}>
            <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
              <Typography
                sx={{ fontWeight: "500", fontSize: "16px", color: "#F2F9FC" }}
              >
                Reach Us:
              </Typography>
              <Stack direction="row" spacing={2}>
                <img src="" alt="" />
                <img src="" alt="" />
                <img src="" alt="" />
                <img src="" alt="" />
              </Stack>
              <Typography
                sx={{ fontWeight: "300", fontSize: "16px", color: "#D5E2E9" }}
              >
                Email: sunrisediagnosticservice@gmail.com
              </Typography>
              <Typography
                sx={{ fontWeight: "300", fontSize: "16px", color: "#D5E2E9" ,textAlign:"center"}}
              >
               Main Office: H.No.-65 Damodar nagar Society Near Dukhi lal building Kanpur 208027<br/>
               Branch office: H.No.-104A Sudarshan Society Vinayakpur Kanpur 208024
              </Typography>
              <Typography
                sx={{ fontWeight: "300", fontSize: "16px", color: "#D5E2E9" }}
              >
                {/* GSTIN: 03AAPFC5564N1ZZ */}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}

export default Footer;
