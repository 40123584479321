import React, { useState } from 'react';
import { Stack, Typography, Input, Button } from '@mui/material';
import axios from 'axios';

function FooterForm() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    requirement: ''
  });

  const [formStatus, setFormStatus] = useState({
    message: '',
    success: false
  });

  // Handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple validation
    if (!formData.name || !formData.phone || !formData.email || !formData.subject || !formData.requirement) {
      setFormStatus({ message: 'All fields are required.', success: false });
      return;
    }

    try {
      const response = await axios.post(`https://api.sunrisediagnosticservice.com/api/requirement/add`, formData);
      console.log(response)
      setFormStatus({ message: 'Requirement submitted successfully!', success: true });
      setFormData({ name: '', phone: '', email: '', subject: '', requirement: '' }); // Clear form
    } catch (error) {
      setFormStatus({ message: 'Failed to submit the requirement. Please try again later.', success: false });
    }
  };

  return (
    <Stack sx={{ width: "100%", justifyContent: "center", alignItems: "center", backgroundColor: "#EFF5FE", padding:"3rem 0" }}>
      <form onSubmit={handleSubmit} style={{width:{xs:"100%",lg:"80%"}}}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2 }}
          sx={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", width: { xs: '90%', md: '100%' }, height: "auto", backgroundColor: "#FCFDFE", borderRadius: 2, alignItems: "center", justifyContent: "space-evenly", padding: { xs: 2 ,md:6} }}>
          
          {/* Form section */}
          <Stack sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
            <Typography textAlign={{ xs: 'center', md: 'left' }} sx={{ fontSize: "20px", fontWeight: "600" }}>Tell us What you need, and we’ll help you</Typography>

            <Stack direction="row" sx={{ width: { xs: '100%', md: '95%' }, justifyContent: "space-between", mt: 2 }}>
              <Stack sx={{ width: "45%" }}>
                <Typography sx={{ fontSize: "16px", marginBottom: "0.5rem" }}>Name</Typography>
                <Input name="name" placeholder="Your Name" value={formData.name} onChange={handleChange}
                  sx={{ backgroundColor: "#F3F5F9", width: "100%", height: "2.5rem", borderRadius: "0.3rem", padding: 1 }} />
              </Stack>
              <Stack sx={{ width: "45%" }}>
                <Typography sx={{ fontSize: "16px", marginBottom: "0.5rem" }}>Phone Number</Typography>
                <Input name="phone" placeholder="Your Phone Number" value={formData.phone} onChange={handleChange}
                  sx={{ backgroundColor: "#F3F5F9", width: "100%", height: "2.5rem", borderRadius: "0.3rem", padding: 1 }} />
              </Stack>
            </Stack>

            <Stack direction="row" sx={{ width: { xs: '100%', md: '95%' }, justifyContent: "space-between", mt: 2 }}>
              <Stack sx={{ width: "45%" }}>
                <Typography sx={{ fontSize: "16px", marginBottom: "0.5rem" }}>Email ID</Typography>
                <Input name="email" placeholder="Your Email" value={formData.email} onChange={handleChange}
                  sx={{ backgroundColor: "#F3F5F9", width: "100%", height: "2.5rem", borderRadius: "0.3rem", padding: 1 }} />
              </Stack>
              <Stack sx={{ width: "45%" }}>
                <Typography sx={{ fontSize: "16px", marginBottom: "0.5rem" }}>Subject</Typography>
                <Input name="subject" placeholder="Subject" value={formData.subject} onChange={handleChange}
                  sx={{ backgroundColor: "#F3F5F9", width: "100%", height: "2.5rem", borderRadius: "0.3rem", padding: 1 }} />
              </Stack>
            </Stack>

            <Stack sx={{ width: { xs: '100%', md: '95%' }, mt: 2 }}>
              <Typography sx={{ fontSize: "16px", marginBottom: "0.5rem" }}>Describe your requirements</Typography>
              <textarea name="requirement" placeholder="Describe your requirements" value={formData.requirement} onChange={handleChange}
                style={{ backgroundColor: "#F3F5F9", width: { xs: '95%', md: '100%' }, height: "5rem", borderRadius: "0.3rem", padding: "0.5rem" }} />
            </Stack>

            <Stack sx={{ width: { xs: '100%', md: '95%' }, alignItems: "flex-end", mt: 3 }}>
              <Button type="submit" sx={{ backgroundColor: "#EEA92B", width: "50%" }}>Submit Requirement</Button>
            </Stack>

            {formStatus.message && (
              <Typography sx={{ mt: 2, color: formStatus.success ? 'green' : 'red' }}>
                {formStatus.message}
              </Typography>
            )}
          </Stack>

          {/* Divider */}
          <Stack sx={{ width: "0.2%", height: "85%", backgroundColor: "blue" }}></Stack>

          {/* Contact details section */}
          <Stack sx={{ width: { xs: '100%', md: '30%' }, justifyContent: "center", alignItems: { xs: 'center', md: 'flex-start' }, padding: "0 1rem" }}>
            <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>Other ways to Connect</Typography>

            <Stack sx={{ width: "100%", mt: 2 }}>
              <Typography sx={{ color: "#0D65B7", fontSize: "18px", marginBottom: "0.5rem" }}>Call Us directly at</Typography>
              <Stack>
                <Typography><img alt="" /> 7524698154</Typography>
                {/* <Typography><img alt="" /> 7524698154</Typography> */}
              </Stack>
            </Stack>

            <Stack sx={{ width: "100%", mt: 2 }}>
              <Typography sx={{ color: "#0D65B7", fontSize: "18px", marginBottom: "0.5rem" }}>Email Us at</Typography>
              <Stack>
                <Typography><img alt="" /> sunrisediagnosticservice@gmail.com</Typography>
                {/* <Typography><img alt="" /> info@example.com</Typography> */}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}

export default FooterForm;
