import React, { useState, useEffect } from "react";
import ProductBox from "./ProductBox";
import { Stack, Typography, Grid } from "@mui/material";
import axios from "axios";

function DiscountedProducts() {
  const [discountedProducts, setDiscountedProducts] = useState([]);

  // Fetch the products from the backend
  useEffect(() => {
    const fetchDiscountedProducts = async () => {
      try {
        // Replace this URL with your actual backend endpoint
        const response = await axios.get(
          `https://api.sunrisediagnosticservice.com/api/products/all`
        );
        const allProducts = response.data;

        // Filter products with a discount greater than 0
        const discounted = allProducts.filter(
          (product) => product.discount && product.discount > 0
        );

        // Get the latest 4 discounted products
        const latestDiscountedProducts = discounted.slice(0, 4);
        setDiscountedProducts(latestDiscountedProducts);
      } catch (error) {
        console.error("Error fetching discounted products:", error);
      }
    };

    fetchDiscountedProducts();
  }, []);

  return (
    <Stack
      spacing={2}
      sx={{
        width: "100%",
        flexDirection: "column",
        padding: "4rem 0",
        backgroundColor: "#EFF5FE",
        alignItems: "center",
      }}
    >
      <Stack sx={{ width: "88%" }}>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "500",
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          Discounted Products
        </Typography>
      </Stack>
      <Grid
        container
        spacing={2}
        sx={{
          width: "95%",
          justifyContent: "center",
          padding: "0 2rem",
        }}
      >
        {discountedProducts.map((product) => (
          <Grid
            item
            key={product._id} // Use _id from the fetched data
            xs={12}
            sm={6} // 1 item per row on small screens
            md={3} // 3 items per row on medium screens
            lg={3} // 4 items per row on large screens
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <ProductBox
              // If product.images is empty, show a placeholder image
              img={
                product.images.length > 0
                  ? product.images[0]
                  : "/placeholder-image.png"
              }
              ProNum={product.model} // Using the product model as the product number
              ProTitle={product.productName}
              ProSubTitle={product.instrumentName || product.usageApplication} // Using instrumentName or usageApplication as subtitle
              brandName={product.brand}
              price={` ${product?.price?.value}`} // Format the price properly
              discount={product.discount} // Pass the discount (if needed for display)
              images={product.images}

            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}

export default DiscountedProducts;
