import { Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Import your images here for models
import ST200 from "../assets/ST-200.png";
import Maglumi800 from "../assets/maglumi-800-1.png";
import Maglumi8002 from "../assets/maglumi-800-2.png"
import Maglumi8003 from "../assets/maglumi-800-3.png"
import SACCA from "../assets/sacca.png";
import SACCA2 from "../assets/sacca-2.png"
import BA88A from "../assets/ba88a.png";
import BA88A2 from "../assets/ba88a-2.png"
import BC20S from "../assets/bc20s.png";
import BC20S2 from "../assets/bc20s-2.png"
import BC5130 from "../assets/bc5130.png";
import BC51302 from "../assets/bc5130-2.png"
import BS240 from "../assets/bs240.png";
import BS2402 from "../assets/BS-240-2.png"
import BC20 from "../assets/bc20.png";
import BC202 from "../assets/bc20-2.png"
import BS230 from "../assets/bs230.png";
import BS2302 from "../assets/bs230-2.png"
import CL900I from "../assets/cl900i.png";
import CL900I2 from "../assets/cl900i-2.png"
import BC6000 from "../assets/bc6000.png";
import BC60002 from "../assets/bc-6000-22.png"
import BS240E from "../assets/bs24oe.png";
import BS240E2 from "../assets/bs240e-2.png"
function DetailsDisplay({ productId }) {
  const [productDetails, setProductDetails] = useState(null);
  const navigate = useNavigate(); // Hook for navigation
  const [image1, setImage1] = useState('');
  const [image2, setImage2] = useState('');
  const [image3, setImage3] = useState('');
  const [mainImage, setMainImage] = useState(''); // Initially empty

  // Image mapping based on product model name
  const imageMapping = {
    "ST-200": [ST200],
    "Maglumi 800": [Maglumi800,Maglumi8002,Maglumi8003],
    "BA-88A": [BA88A,BA88A2],
    "BC-20s": [BC20S,BC20S2],
    "BC-5130": [BC5130,BC51302],
    "BS-240": [BS240,BS2402],
    "BC-20": [BC20,BC202],
    "BS-230": [BS230,BS2302],
    "CL-900i": [CL900I,CL900I2],
    "BC-6000": [BC6000,BC60002],
    "BS-240E": [BS240E,BS240E2],
    "SA-CCA": [SACCA,SACCA2],
  };

  useEffect(() => {
    axios.get(`https://api.sunrisediagnosticservice.com/api/products/${productId}`)
      .then(response => {
        const product = response.data;
        setProductDetails(product);

        // If product images are available, set them, otherwise use mapped model images
        // const productImages = product.images || [];
        const fallbackImages = imageMapping[product.model] || [];
        console.log(imageMapping[product.model],fallbackImages[0])
        setImage1(fallbackImages[0] );
        setImage2(fallbackImages[1] || '');
        setImage3( fallbackImages[2] || '');
        setMainImage(fallbackImages[0] || '');
      })
      .catch(error => console.error('Error fetching product details:', error));
  }, [productId]);

  const handleAddToCart = () => {
    const userId = localStorage.getItem('userId'); // Replace with the actual user ID

    axios.post(`https://api.sunrisediagnosticservice.com/api/orders/add`, {
      user: userId,
      products: [{ product: productDetails._id, quantity: 1 }],
      total: productDetails.price.value || 200,
      status: 'Pending', // Initial status
    })
      .then(response => {
        console.log('Product added to cart:', response.data);
        navigate('/cart-screen'); // Redirect to cart screen after adding to cart
      })
      .catch(error => console.error('Error adding product to cart:', error));
  };

  return (
    <>
      <Stack sx={{ width: "100%", height: "auto", justifyContent: "center", alignItems: "center", backgroundColor: "#EFF5FE", padding: 3 }}>
        <Stack direction={{ xs: "column", md: "column", lg: "row" }} sx={{ width: { xs: "100%", md: "100%", lg: "75%" }, justifyContent: "space-between", alignItems: "center" }} >
          <Stack direction="row" sx={{ width: { xs: "80%", md: "50%", lg: "45%" }, justifyContent: "space-between" }}>
            <Stack sx={{ width: "30%", justifyContent: "flex-start", alignItems: "center" }} spacing={1}>
              {/* Thumbnail images with onClick to change main image */}
              {image1 && <img src={image1} alt="" style={{ width: "88%", height: "30%", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", cursor: "pointer" }} onClick={() => setMainImage(image1)} />}
              {image2 && <img src={image2} alt="" style={{ width: "88%", height: "30%", cursor: "pointer" }} onClick={() => setMainImage(image2)} />}
              {image3 && <img src={image3} alt="" style={{ width: "88%", height: "30%", cursor: "pointer" }} onClick={() => setMainImage(image3)} />}
            </Stack>

            {/* Main image */}
            <Stack sx={{ width: "80%", height: "80%", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)" }}>
              <img src={mainImage} alt="" style={{ width: "100%", height: "100%" }} />
            </Stack>
          </Stack>
          <Stack sx={{ width: { xs: "80%", md: "50%", lg: "45%" } }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "600", color: "#0D65B7" }}>
              {productDetails?.model} Auto Hematology Analyzer
            </Typography>
            <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center", marginTop: 2 }}>
              <Stack sx={{ width: "40%" }} spacing={2}>
                <Typography sx={{ fontSize: "17px", fontWeight: "500" }}>Instrument Name</Typography>
                <Typography sx={{ fontSize: "17px", fontWeight: "500" }}>Model Name/Number</Typography>
                <Typography sx={{ fontSize: "17px", fontWeight: "500" }}>Brand</Typography>
                <Typography sx={{ fontSize: "17px", fontWeight: "500" }}>Usage/Application</Typography>
                <Typography sx={{ fontSize: "17px", fontWeight: "500" }}>Weight/Height</Typography>
                <Typography sx={{ fontSize: "17px", fontWeight: "500" }}>Display</Typography>
                <Typography sx={{ fontSize: "17px", fontWeight: "500" }}>Data Storage</Typography>
                <Typography sx={{ fontSize: "17px", fontWeight: "500" }}>Throughput</Typography>
                <Typography sx={{ fontSize: "17px", fontWeight: "500" }}>Price</Typography>
              </Stack>
              <Stack sx={{ width: "50%" }} spacing={2.5}>
                <Typography sx={{ fontSize: "16px", fontWeight: "300" }}>{productDetails?.productName}</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "300" }}>{productDetails?.model}</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "300" }}>{productDetails?.brand}</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "300" }}>{productDetails?.usageApplication}</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "300" }}>{productDetails?.weight}/{productDetails?.height}</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "300" }}>{productDetails?.display}</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "300" }}>{productDetails?.dataStorage}</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "300" }}>{productDetails?.throughput}</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "300" }}>
                  ₹{productDetails?.price?.value}
                </Typography>
              </Stack>
            </Stack>

            <Stack sx={{ width: "70%", height: "10%", alignItems: "center", mt: 2 }}>
              <Button
                onClick={handleAddToCart}
                sx={{ width: "45%", height: "100%", borderRadius: "0.3rem", backgroundColor: "#EEA92B", color: "white", fontWeight: "400", display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                ADD TO CART
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ width: "100%", height: "10rem", justifyContent: "center", alignItems: "center", backgroundColor: "#EFF5FE" }}>
        <Stack sx={{ width: "75%", justifyContent: "center", alignItems: "flex-start", backgroundColor: "#EFF5FE" }} spacing={2}>
        {productDetails?.description &&   <Typography>Product Description</Typography>}
          <Typography>{productDetails?.description}</Typography>
        </Stack>
      </Stack>
    </>
  );
}

export default DetailsDisplay;
