import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  // Typography,
} from "@mui/material";

function OrderTableAdmin() {
  const [orders, setOrders] = useState([]);

  // Fetch orders from the API
  useEffect(() => {
    axios
      .get(`https://api.sunrisediagnosticservice.com/api/orders/all`) // Adjust the endpoint according to your API
      .then((response) => {
        setOrders(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the orders!", error);
      });
  }, []);
 console.log(orders)
  return (
    <Stack
      sx={{
        width: "100%",
        backgroundColor: "#EFF5FE",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
      spacing={1}
    >
      <Stack sx={{ width: "95%", height: "88%" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="order table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#0D65B7" }}>
                <TableCell
                  sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                >
                  Order ID
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                >
                  Customer Name
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                >
                  Total Amount
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                >
                  Address
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                >
                  Payment Mode
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow key={order._id}>
                  <TableCell>{order._id}</TableCell>
                  <TableCell>{order.user.name}</TableCell>
                  <TableCell>{new Date(order.date).toLocaleDateString()}</TableCell>
                  <TableCell>₹ {order.total}</TableCell>
                  <TableCell>₹ {order.address}</TableCell>
                  <TableCell>₹ {order.paymentMethod}</TableCell>
                  <TableCell>{order.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
}

export default OrderTableAdmin;
