import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";

function UserTableAdmin() {
  const [users, setUsers] = useState([]);

  // Fetch users from the API
  useEffect(() => {
    axios
      .get(`https://api.sunrisediagnosticservice.com/api/users/all`) // Replace with your actual API endpoint
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the users!", error);
      });
  }, []);

  return (
    <Stack
      sx={{
        width: "100%",
        backgroundColor: "#EFF5FE",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
      spacing={1}
    >
      <Stack sx={{ width: "95%", height: "88%" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="user table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#0D65B7" }}>
                <TableCell
                  sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                >
                  Phone
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "17px", fontWeight: "500" }}
                >
                  Registration Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                  <TableCell>
                    {new Date(user.createdAt).toLocaleDateString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
}

export default UserTableAdmin;
