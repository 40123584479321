import React from 'react';
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import banner1 from "../assets/banner1.png";
import banner2 from "../assets/banner2.png";
import banner3 from "../assets/banner3.png";
import banner4 from "../assets/banner4.png";
import banner5 from "../assets/banner5.png";
import banner6 from "../assets/banner6.png";

import { Stack } from '@mui/material';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import '../ExtraCss.css';

function Header() {
    const banners = [
        { backgroundImage: banner3 },
        { backgroundImage: banner4 },
        { backgroundImage: banner1 },
        { backgroundImage: banner2 },
        { backgroundImage: banner5 },
        { backgroundImage: banner6 }
    ];

    return (
        <div>
            <Swiper
                modules={[Pagination, Autoplay]}
                pagination={{
                    dynamicBullets: true,
                    clickable: true
                }}
                scrollbar={{ draggable: true }}
                className="mySwiper"
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
            >
                {banners.map((banner, index) => (
                    <SwiperSlide key={index}>
                        <Stack sx={{
                            width: '100%',
                            height: {xs:"20rem",md:'30rem'},
                            display: "flex",
                            flexDirection: "column",
                            alignItems: {
                                xs: "center",
                                md: "flex-start"
                            },
                            justifyContent: "center",
                            // backgroundImage: `url(${banner.backgroundImage})`,
                            // backgroundSize: 'cover',
                            // backgroundRepeat:"no-repeat",
                            gap: "2rem"
                        }}>
                            <img style={{width:"100%",height:"100%"}} src={banner.backgroundImage} alt="" />
                            {/* Additional content inside the slide can be added here */}
                        </Stack>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default Header;
