import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Navbar from './components/Navbar';
// import NavbarSearch from './components/NavbarSearch';
import Footer from './components/Footer';
import FooterForm from './components/FooterForm';
import ProductDetails from './pages/ProductDetails';
import AllProducts from './pages/AllProducts';
import CartScreen from './pages/CartScreen';
import Auth from './pages/Auth';
import AdminAuth from './pages/AdminAuth';
import AdminPage from './pages/AdminPage';
import About from './pages/About';

function PrivateRoute({ element, adminOnly, ...rest }) {
  const isAuthenticated = !!localStorage.getItem('jwtToken'); // Check for admin token
  const isAdmin = !!localStorage.getItem('isAdmin'); // Check admin status

  if (adminOnly && !isAdmin) {
    return <Navigate to="/admin" />; // Redirect to admin login if not an admin
  }

  return isAuthenticated ? element : <Navigate to="/signup" />;
}

function Layout({ children }) {
  const location = useLocation();
  const hideHeaderFooter = ['/signup', '/admin', '/admin-page'].includes(location.pathname);

  return (
    <>
      {!hideHeaderFooter && <Navbar />}
      {children}
      {!hideHeaderFooter && <FooterForm />}
      {!hideHeaderFooter && <Footer />}
    </>
  );
}

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/signup" element={<Auth />} />
          <Route path="/admin" element={<AdminAuth />} />
          <Route path="/about" element={<About />} />
          
          {/* Admin page protected route */}
          <Route
            path="/admin-page"
            element={<PrivateRoute adminOnly element={<AdminPage />} />}
          />
  
          {/* Other protected routes */}
          <Route path="/product/:productId" element={<PrivateRoute element={<ProductDetails />} />} />
          <Route path="/shop" element={<AllProducts />} />
          <Route path="/cart-screen" element={<PrivateRoute element={<CartScreen />} />} />
          <Route path="/home" element={<HomePage />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
